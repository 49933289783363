import { Messages } from "../../../../config/messages/messages";

export const vsmTrackShipment = {
	success: Messages.CREATED,
	edit: Messages.UPDATED,
	error: Messages.DUPLICATED,
	validation: {
		fy_id: [{ required: true, message: Messages.NOT_SELECTED }],
		con_no: [
			{ required: true, message: Messages.REQUIRED },
		]
	},
};