import React, { useState, useEffect } from "react";
import { Form, Button, Drawer, Row, Col, Divider, Switch, Alert, Spin, Tooltip } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../../../../config/Constant";
import debounce from "lodash/debounce";
import InputComponent from "../../../../../component/InputComponent";
import { vsmLocation } from "../Validation";
import DefaulHeader from "./DefaulHeader";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";

const LegalOperationsComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageCentersStore,
		ManageCentersStore: { setLegalOperationData, loValues, typeMode, editValues },
		CommonStore,
		OwnerShipTypeStore,
		PartnerShipTypeStore,
		ManagePinCodeStore,
		ManageBankAccountHoStore,
		ManageBankAccountAliasStore,
		CommonStore: { dropdown_bank_account_type_list },
		ManagePaymentModeStore,
		AUTH
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchLocationTypes, setFetchLocationTypes] = useState(true);
	const [fetchLocationList, setFetchLocationList] = useState(true)
	const [fetchOwnershipType, setFetchOwnershipType] = useState(true);
	const [fetchPartnerShipType, setFetchPartnerShipType] = useState(true);
	const [fetchSez, setFetchSez] = useState(true);
	const [fetchBankAccHo, setFetchBankAccHo] = useState([]);
	const [fetchBankAlias, setFetchBankAlias] = useState(true);
	const [isViewPurpose, setIsViewPurpose] = useState();
	const [changeap, setChangeap] = useState(true);
	const [submitValue, setSubmit] = useState();
	const [officePermisesValue, setOfficePremisesValue] = useState(true);
	const [fetchMode, setFetchMode] = useState(true)
	const [ot_ids, setOtids] = useState()
	const [paymentData, setPaymentData] = useState();
	const [depBank, setDepBank] = useState(true)
	const [depBankData, setDepBankData] = useState();
	const [bankAlias, setBankAlias] = useState();
	const [depositData, setDepositData] = useState();
	const [parentCenterList, setParentCenterList] = useState(null);

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = editValues.id;
		data.allow_ap = (data.allow_ap) ? 1 : 0;
		data.allow_qr = (data.allow_qr) ? 1 : 0;
		data.disp_date = data?.disp_date ? moment(data.disp_date).format(Constant.SERVER_DATE_FORMAT) : null
		data.ack_date = data?.ack_date ? moment(data.ack_date).format(Constant.SERVER_DATE_FORMAT) : null
		if (loValues.deposit > 0) {
			delete data.deposit;
			delete data.deposit_date;
			delete data.dep_ref_no;
			delete data.dep_mode_id;
			delete data.dep_bank_id;
		}
		data.is_computerized = (data.is_computerized) ? 1 : 0;
		if (loValues.deposit < 0 || loValues.deposit === 0) {
			if (data.deposit_date) {
				data.deposit_date = moment(data.deposit_date).utcOffset(Constant.UTCOFFSET).format("YYYY-MM-DD");
			}
		}
		if (data.from_pincode) {
			let from_pincode = ManagePinCodeStore.dropdown_pincodes_list.find((item) => item.id === data.from_pincode)
			data.from_pincode = from_pincode.pincode;
		}
		setLegalOperationData(data)
			.then((data) => {
				close(submitValue);
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// set the form values to edit
	useEffect(() => {
		if (loValues && props.visible) {
			let view = (typeMode === Constant.VIEW) ? true : false;
			setIsViewPurpose(view);
			if (loValues.center_type) {
				CommonStore.dropdown_center_types_list = [loValues.center_type]
			}
			if (loValues.ownership) {
				OwnerShipTypeStore.dropdown_OwnershipType_list = [loValues.ownership]
			} else {
				OwnerShipTypeStore.dropdown_OwnershipType_list = [{
					id: loValues?.mst_franchisee?.ownership_types?.id,
					name: loValues?.mst_franchisee?.ownership_types?.name
				}]
			}
			if (loValues.partnership) {
				PartnerShipTypeStore.dropdown_partnership_types_list = [loValues.partnership]
			}
			if (loValues.sez) {
				CommonStore.dropdown_sez_list = [loValues.sez]
			}
			if (loValues.mst_franchisee) {
				setChangeap((loValues.mst_franchisee.allow_ap) ? true : false)
			}
			if (loValues.parent) {
				setParentCenterList([loValues.parent]);
			}

			if (loValues.from_pincode) {
				ManagePinCodeStore.dropdown_pincodes_list = [loValues.from_pincode]
			}

			if (loValues.ho_bank_acc) {
				ManageBankAccountHoStore.dropdown_bank_account_ho_list = [loValues.ho_bank_acc]
				setBankAlias(ManageBankAccountHoStore.dropdown_bank_account_ho_list)
			}

			if (loValues.ho_bank_acc_alias) {
				ManageBankAccountAliasStore.dropdown_bank_acc_alias_list = [loValues.ho_bank_acc_alias]
			}
			CommonStore.dropdown_office_premises_list = [loValues.office_premises]
			let hoListobj = [];
			if (ManageBankAccountHoStore.dropdown_bank_account_ho_list) {
				hoListobj.push({
					id: loValues.ho_bank_acc?.lov_bank_acc_types?.id,
					name: loValues.ho_bank_acc?.lov_bank_acc_types?.name
				});
			}
			if (loValues.dep_mode_id) {
				ManagePaymentModeStore.dropdown_payment_mode_list = [loValues.dep_payment_modes]
				setPaymentData(loValues.dep_payment_modes)
			}
			if (loValues.dep_bank_acc) {
				form.setFieldsValue({
					acc_no: loValues.dep_bank_acc?.accno
				})
				ManageBankAccountHoStore.dropdown_bank_account_ho_list = [loValues?.dep_bank_acc]
				setDepBankData(ManageBankAccountHoStore.dropdown_bank_account_ho_list)
			}
			setOtids(loValues.op_id)
			form.setFieldsValue({
				ct_id: loValues.ct_id,
				ot_id: (loValues?.ot_id) ? (loValues?.ot_id) : (loValues.mst_franchisee?.ownership_types?.id),
				pt_id: loValues.pt_id,
				sez_id: loValues.sez_id,
				parent_id: loValues.parent_id,
				allow_ap: loValues.allow_ap,
				allow_qr: loValues.allow_qr,
				is_computerized: loValues.is_computerized,
				op_state: loValues.op_states.name,
				from_pincode: loValues?.from_pincode?.id,
				operation_note: loValues.operation_note,
				ho_bank_acc_id: loValues.ho_bank_acc_id,
				ho_bank_alias_id: loValues.ho_bank_acc_alias?.id,
				accno: loValues.ho_bank_acc?.accno,
				ifsc: loValues.ho_bank_acc?.ifsc,
				cb_series: loValues?.cb_series,
				ab_series: loValues?.ab_series,
				chq_name: loValues.ho_bank_acc?.chq_name,
				acc_type_id: hoListobj[0]?.name,
				micr: loValues.ho_bank_acc?.micr,
				op_id: loValues?.op_id,
				office_rent: loValues?.office_rent,
				ll_name: loValues?.ll_name,
				ll_mobile: loValues?.ll_mobile,
				deposit_date: (loValues?.deposit_date) ? moment(loValues?.deposit_date).utcOffset(Constant.UTCOFFSET) : null,
				dep_mode_id: loValues?.dep_payment_modes?.id,
				dep_ref_no: loValues?.dep_ref_no,
				deposit: loValues?.deposit,
				dep_bank_id: loValues?.dep_bank_id,
				external_lock_tracking: loValues?.external_lock_tracking,
				is_net_display: loValues?.is_net_display,
				// idbi_va_no : loValues?.idbi_va_no,
				// idbi_va_upi_id : loValues?.idbi_va_upi_id
				disp_date: (loValues?.disp_date) ? moment(loValues.disp_date).utcOffset(Constant.UTCOFFSET) : null,
				ack_date: (loValues?.ack_date) ? moment(loValues.ack_date).utcOffset(Constant.UTCOFFSET) : null,
			});
		}
	}, [loValues, props, form, CommonStore, OwnerShipTypeStore, ManagePaymentModeStore, PartnerShipTypeStore, ManagePinCodeStore, ManageBankAccountHoStore, ManageBankAccountAliasStore, typeMode]);

	const disabledDispDate = current => {
		return current && current > moment().endOf("day");
	};

	const disabledAckDate = current => {
		const dispatchDate = form.getFieldValue("disp_date") ?? null;
		if (dispatchDate) {
			const today = moment().endOf("day");
			return current && (moment(current).endOf("day") > today || moment(current).endOf("day") < moment(dispatchDate).endOf("day"));
		} else {
			return true;
		}
	};
	

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);


	// reset form and close add form
	const close = (submitValue) => {
		if (submitValue === "submit") {
			props.close();
		} else if (submitValue === "submitNext") {
			props.closeNext();
		}
		setSubmit()
		setFetchLocationTypes(true);
		setFetchOwnershipType(true);
		setFetchPartnerShipType(true);
		setFetchSez(true);
		setFetchBankAccHo([]);
		setFetchBankAlias(true);
		setPaymentData();
		setFetchMode(true)
		setDepBank(true)
		setBankAlias()
		setDepositData()
		ManagePaymentModeStore.dropdown_payment_mode_list = null;
		CommonStore.dropdown_center_types_list = null
		OwnerShipTypeStore.dropdown_OwnershipType_list = null;
		PartnerShipTypeStore.dropdown_partnership_types_list = null;
		CommonStore.dropdown_sez_list = null;
		ManagePinCodeStore.dropdown_pincodes_list = null;
		ManageBankAccountHoStore.dropdown_bank_account_ho_list = null;
		ManageBankAccountAliasStore.dropdown_bank_acc_alias_list = null;
		setOfficePremisesValue(true);
		CommonStore.dropdown_office_premises_list = null;
		setOtids()
		form.resetFields();
		setDisabled(true);
		setFetchLocationList(true);
		setParentCenterList(null);
	};

	const handleBankAccountChange = (e) => {
		setFetchBankAlias(true);
		let data = ManageBankAccountHoStore.dropdown_bank_account_ho_list.find((item) => item.id === e);
		if (data) {
			form.setFieldsValue({
				accno: data?.accno,
				ifsc: data?.ifsc,
				ho_bank_alias_id: null,
				micr: data?.micr,
				lov_bank_acc_types: data?.lov_bank_acc_types,
				chq_name: data?.chq_name
			});
		} else {
			form.setFieldsValue({
				accno: null,
				ifsc: null,
				ho_bank_alias_id: null,
				micr: null,
				lov_bank_acc_types: null,
				chq_name: null
			});
		}
	};

	const disableDate = (current) => {
		return current.isBefore(moment(Constant.LOCATION_LEGAL_DEPOSITE_DATE).startOf('day')) || current.isAfter(moment().add(5, 'days').startOf('day'))
	};

	return (
		<Drawer
			className="editModal"
			title="Legal Information"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={() => { close("submit") }}
			destroyOnClose={true}
			footer={[
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="1"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={() => { close("submitNext",) }}
				>
					Skip
				</Button>),
				(typeMode !== Constant.VIEW && <Button
					key="2"
					disabled={disabled}
					form="legalOperationForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					className="mr-35"
					onClick={() => {
						setSubmit("submit");
					}}
				>
					Save
				</Button>),
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="3"
					disabled={disabled}
					form="legalOperationForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					onClick={() => {
						setSubmit("submitNext", loValues);
					}}
				>
					Save & Continue
				</Button>),
				(typeMode === Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="4"
					type="primary"
					onClick={() => {
						close("submitNext");
					}}
				>
					Continue
				</Button>)
			]}
		>
			{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
				type="error"
				showIcon
				message={
					(editValues.is_terminated === 1) ? `This location is terminated so you cannot make any changes` : (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
				}
			/>}
			<Form
				form={form}
				id={"legalOperationForm"}
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<DefaulHeader />
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_ct_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_ct_id')}
							label="Location Type"
							name="ct_id"
							placeholder="Select Location Type"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.ct_id}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchLocationTypes &&
								CommonStore.getCenterTypesList({ is_active: 1 }).then(() =>
									setFetchLocationTypes(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchLocationTypes) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_center_types_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									CommonStore.dropdown_center_types_list &&
									CommonStore.dropdown_center_types_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_ot_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_ot_id')}
							label="Ownership Type"
							name="ot_id"
							placeholder="Select Ownership Type"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.ot_id}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchOwnershipType &&
								OwnerShipTypeStore.getOwnershipTypeList({ is_active: 1 }).then(() =>
									setFetchOwnershipType(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchOwnershipType) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: OwnerShipTypeStore.dropdown_OwnershipType_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									OwnerShipTypeStore.dropdown_OwnershipType_list &&
									OwnerShipTypeStore.dropdown_OwnershipType_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_pt_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_pt_id')}
							label="Partnership Type"
							name="pt_id"
							placeholder="Select Partnership Type"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.pt_id}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchPartnerShipType &&
								PartnerShipTypeStore.getPartnerShipTypesList({ is_active: 1 }).then(() =>
									setFetchPartnerShipType(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchPartnerShipType) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: PartnerShipTypeStore.dropdown_partnership_types_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									PartnerShipTypeStore.dropdown_partnership_types_list &&
									PartnerShipTypeStore.dropdown_partnership_types_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_sez_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_sez_id')}
							label="SEZ"
							name="sez_id"
							placeholder="Select SEZ"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.sez_id}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchSez &&
								CommonStore.getSezList({ is_active: 1 }).then(() =>
									setFetchSez(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchSez) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_sez_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									CommonStore.dropdown_sez_list &&
									CommonStore.dropdown_sez_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<Form.Item name="allow_ap" label="Allow Account Party?" valuePropName="checked" required={!isViewPurpose}>
							{(!isViewPurpose && changeap) ?
								<Switch
									className="Switch__with_label"
									checked={(form.getFieldValue('allow_ap')) ? true : false}
									onChange={handleChange}
								/> :
								<Switch
									className="Switch__with_label"
									checked={(form.getFieldValue('allow_ap')) ? true : false}
									disabled
								/>
							}
						</Form.Item>
					</Col>
					<Col xs={{ span: 6 }}>
						<Form.Item name="allow_qr" label="Sticker Payment using QR?" valuePropName="checked" required={!isViewPurpose}>
							{!isViewPurpose ? <Switch
								className="Switch__with_label"
								checked={(form.getFieldValue('allow_qr')) ? true : false}
								onChange={handleChange}
							/> : <Switch
								className="Switch__with_label"
								checked={(form.getFieldValue('allow_qr')) ? true : false}
								disabled
							/>
							}
						</Form.Item>
					</Col>
					<Col xs={{ span: 6 }}>
						<Form.Item name="is_computerized" label="Is Computerized?" valuePropName="checked" required={!isViewPurpose}>
							{!isViewPurpose ? <Switch
								className="Switch__with_label"
								checked={(form.getFieldValue('is_computerized')) ? true : false}
								onChange={handleChange}
							/> : <Switch
								className="Switch__with_label"
								checked={(form.getFieldValue('is_computerized')) ? true : false}
								disabled
							/>
							}
						</Form.Item>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30} >
				{AUTH.checkPrivileges("#12079#") &&
						<Col xs={{ span: 6 }} >
							<Form.Item
								label="Lock Tracking?"
								valuePropName="checked"
								name="external_lock_tracking"
							>
								{!isViewPurpose ? <Switch
									className="Switch__with_label"
									checked={(form.getFieldValue('external_lock_tracking') === 1) ? true : false}
									onChange={(e) => {
										handleChange()
										form.setFieldsValue({ external_lock_tracking: (e) ? 1 : 0 })
									}}
								/> : <Switch
									className="Switch__with_label"
									checked={(form.getFieldValue('external_lock_tracking') === 1) ? true : false}
									disabled
								/>
								}
							</Form.Item>
						</Col>
					}
					{AUTH.user.level_id === Constant.ADMIN_USER &&
						<Col xs={{ span: 6 }} >
							<Form.Item
								label="Display in Network?"
								valuePropName="checked"
								name="is_net_display"
							>
								{!isViewPurpose ? <Switch
									className="Switch__with_label"
									checked={(form.getFieldValue('is_net_display') === 1) ? true : false}
									onChange={(e) => {
										handleChange()
										form.setFieldsValue({ is_net_display: (e) ? 1 : 0 })
									}}
								/> : <Switch
									className="Switch__with_label"
									checked={(form.getFieldValue('is_net_display') === 1) ? true : false}
									disabled
								/>
								}
							</Form.Item>
						</Col>
					}
				</Row>
				<Divider/>
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							disabled
							label="Op.State"
							placeholder="Op.State"
							name="op_state"
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_from_pincode">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_from_pincode')}
							label="From Pin Code"
							name="from_pincode"
							placeholder="Select From Pin Code"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.from_pincode}
							onChange={handleChange}
							onSearch={(e) => {
								if (!isViewPurpose && e.length >= 3 && e.length <= 15) {
									ManagePinCodeStore.getPincodeList({
										is_active: "1",
										pincode: e,
									});
								}
							}}
							notFoundContent={
								(!isViewPurpose && ManagePinCodeStore && ManagePinCodeStore.dropdown_pincodes_list && ManagePinCodeStore.dropdown_pincodes_list.length > 0) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManagePinCodeStore.dropdown_pincodes_list,
								value_key: "id",
								text_key: "pincode",
								disabled_keys:
									ManagePinCodeStore.dropdown_pincodes_list &&
									ManagePinCodeStore.dropdown_pincodes_list
										.filter(
											(item) =>
												item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					{typeMode !== Constant.VIEW &&
						<Col xs={{ span: 6 }}>
							<Button
								key="5"
								onClick={() => {
									props.openAddServiceAreaModal();
								}}
								className="mt-20"
								htmlType="button"
								type="primary"
							>
								Add Service Area
							</Button>
						</Col>
					}
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="textarea"
							readOnly={isViewPurpose}
							label="Operational Note"
							placeholder="Operational Note"
							name="operation_note"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.operation_note}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				{/* <h2>IDBI Info</h2>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="idbi_va_no">
						<InputComponent
							type="text"
							disabled={true}
							label="IDBI Virtual No."
							name="idbi_va_no"
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="IDBI Virtual UPI ID"
							name="idbi_va_upi_id"
						/>
					</Col>
				</Row>
				<Divider className="mt-0" /> */}
				<h2>Landlord Info</h2>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_op_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_op_id')}
							label="Office Premises"
							name="op_id"
							placeholder="Select Office Premises"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.op_id}
							onChange={(e) => {
								setOtids(e)
								handleChange(e)
							}}
							onFocus={() =>
								!isViewPurpose && officePermisesValue &&
								CommonStore.getOfficePremisesList().then(() =>
									setOfficePremisesValue(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && officePermisesValue) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_office_premises_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									CommonStore.dropdown_office_premises_list &&
									CommonStore.dropdown_office_premises_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					{ot_ids === 2 && (
						<>
							<Col xs={{ span: 6 }}>
								<InputComponent
									type="number"
									// required={(ot_ids === 2) ? true : false}
									readOnly={isViewPurpose}
									label="Rent Amount"
									placeholder="Rent Amount"
									name="office_rent"
									onChange={handleChange}
									maxLength={7}
									min={0}
									rules={(isViewPurpose) ? [] : vsmLocation.validationlo.rent_amount}
								/>
							</Col>
							<Col xs={{ span: 6 }}>
								<InputComponent
									type="text"
									// required={(ot_ids === 2) ? true : false}
									readOnly={isViewPurpose}
									label="Rent Landlord Name"
									placeholder="Rent LandLord Name"
									name="ll_name"
									onChange={handleChange}
									rules={(isViewPurpose) ? [] : vsmLocation.validationlo.rental_name}
								/>
							</Col>
							<Col xs={{ span: 6 }}>
								<InputComponent
									type="text"
									readOnly={isViewPurpose}
									// required={(ot_ids === 2) ? true : false}
									label="Rent Landlord Mobile"
									placeholder="Rent LandLord Mobile"
									name="ll_mobile"
									onChange={handleChange}
									maxLength={10}
									rules={(isViewPurpose) ? [] : vsmLocation.validationlo.rental_mobile}
								/>
							</Col>
						</>
					)}
				</Row>
				<h2>Parent Location Info</h2>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_parent_id">
                        <InputComponent
                            type="select"
							readOnly={isViewPurpose}
                            autoComplete="chrome-off"
                            label="Parent Location"
                            name="parent_id"
							allowClear
                            getPopupContainer={() => document.getElementById('parent_parent_id')}
                            placeholder="Select Parent Location"
                            onChange={() => {
                                handleChange();
                            }}
                            onFocus={() =>
                                !isViewPurpose && fetchLocationList &&
                                ManageCentersStore.getCentersList({ without_parent_id: loValues.id, hub_id: loValues.hub_id, is_active: 1 }).then((data) =>{
									setParentCenterList(data.mst_center);
									setFetchLocationList(false)
								})
                            }
                            notFoundContent={
                                !isViewPurpose && fetchLocationList ? <Spin size="small" /> : "No Record Found."
                            }
                            options={{
                                values: parentCenterList,
                                value_key: "id",
                                text_key: "name",
                            }}
                        />
                    </Col>
				</Row>
				<Divider className="mt-0" />
				<h2>Head Office Bank Details</h2>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_ho_bank_acc_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_ho_bank_acc_id')}
							label="Bank Name"
							name="ho_bank_acc_id"
							placeholder="Select Bank Account"
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.ho_bank_acc_id}
							onChange={(e) => {
								if (ManageBankAccountHoStore.dropdown_bank_account_ho_list) {
									let selected = ManageBankAccountHoStore.dropdown_bank_account_ho_list.find(element => element.id === e);
									setFetchBankAccHo([selected?.lov_bank_acc_types]);
									form.setFieldsValue({
										acc_type_id: selected?.lov_bank_acc_types?.id
									})
								}
								handleChange(e);
								handleBankAccountChange(e)
							}}
							onFocus={() =>
								!isViewPurpose && fetchBankAccHo &&
								ManageBankAccountHoStore.getBankAccountHoList({ is_active: 1 })
									.then((data) => {
										setBankAlias(data.bank_accounts)
										setFetchBankAccHo(false);
									})
							}
							notFoundContent={
								(!isViewPurpose && fetchBankAccHo) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: bankAlias,
								value_key: "id",
								text_key: "title",
								disabled_keys:
									ManageBankAccountHoStore.dropdown_bank_account_ho_list &&
									ManageBankAccountHoStore.dropdown_bank_account_ho_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 0 }} id="parent_ho_bank_alias_id">
						<InputComponent
							type="select"
							hidden
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							allowClear
							getPopupContainer={() => document.getElementById('parent_ho_bank_alias_id')}
							label="Bank Account Alias"
							name="ho_bank_alias_id"
							placeholder="Select Bank Account Alias"
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchBankAlias && form.getFieldValue('ho_bank_acc_id') &&
								ManageBankAccountAliasStore.getBankAccAliasList({ is_active: 1, bank_acc_id: form.getFieldValue('ho_bank_acc_id') })
									.then(() => {
										setFetchBankAlias(false);
									})
							}
							notFoundContent={
								(!isViewPurpose && fetchBankAlias) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageBankAccountAliasStore.dropdown_bank_acc_alias_list,
								value_key: "id",
								text_key: "alias",
								disabled_keys:
									ManageBankAccountAliasStore.dropdown_bank_acc_alias_list &&
									ManageBankAccountAliasStore.dropdown_bank_acc_alias_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Account Number"
							placeholder="Account Number"
							name="accno"
							disabled
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="IFSC"
							placeholder="IFSC"
							name="ifsc"
							disabled
						/>
					</Col>
				</Row>
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="select"
							allowClear={isViewPurpose}
							autoComplete="chrome-off"
							label="Account Type"
							name="acc_type_id"
							readOnly
							disabled
							getPopupContainer={() => document.getElementById('parent_acc_type_id')}
							placeholder="Select Account Type"
							options={{
								values: fetchBankAccHo ? fetchBankAccHo : dropdown_bank_account_type_list,
								value_key: "id",
								text_key: "name",
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly
							disabled
							label="Name on Cheque"
							placeholder="Name on Cheque"
							name="chq_name"
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly
							disabled
							label="MICR"
							placeholder="MICR"
							name="micr"
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<h2>Deposit Details</h2>
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							label="Deposit Amount"
							readOnly={isViewPurpose}
							placeholder="Deposit Amount"
							disabled
							onChange={(e) => {
								handleChange(e);
								setDepositData(e.target.value)
							}}
							rules={(isViewPurpose) ? [{ required: false }] : vsmLocation.validationlo.amount}
							name="deposit"
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="date"
							required={!isViewPurpose && depositData > 0}
							readOnly={isViewPurpose}
							label="Deposit Date"
							name="deposit_date"
							placeholder="Deposit Date"
							disabledDate={disableDate}
							disabled
							format={Constant.DEFAULT_DATE_FORMAT}
							onChange={handleChange}
							rules={(isViewPurpose) ? [{ required: false }] : depositData > 0 ? vsmLocation.validationlo.dep_date : [{ required: false }]}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_dep_mode_id">
						<InputComponent
							type="select"
							required={(!isViewPurpose && depositData > 0)}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_dep_mode_id')}
							label="Mode of Payment"
							name="dep_mode_id"
							disabled
							placeholder="Select Mode Of Payment"
							rules={(isViewPurpose) ? [{ required: false }] : depositData > 0 ? vsmLocation.validationlo.dep_mode_id : [{ required: false }]}
							onChange={(e) => {
								if (ManagePaymentModeStore.dropdown_payment_mode_list) {
									let selected = ManagePaymentModeStore.dropdown_payment_mode_list.find(element => element.id === e);
									setPaymentData(selected)
									if (selected?.ref_flag === 0) {
										form.setFieldsValue({ dep_ref_no: null })
									}
								}
								handleChange(e);
							}}
							onFocus={() =>
								!isViewPurpose && fetchMode &&
								ManagePaymentModeStore.getPaymentModeList({ is_active: 1, purpose: "2" })
									.then(() => {
										setFetchMode(false);
									})
							}
							notFoundContent={
								(!isViewPurpose && fetchMode) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManagePaymentModeStore.dropdown_payment_mode_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManagePaymentModeStore.dropdown_payment_mode_list &&
									ManagePaymentModeStore.dropdown_payment_mode_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							label="Reference No"
							readOnly={isViewPurpose}
							placeholder="Reference No"
							disabled
							required={paymentData?.ref_flag === 1 ? true : false}
							name="dep_ref_no"
							rules={paymentData?.ref_flag === 0 ? [{ required: false }] : depositData > 0 ? vsmLocation.validationlo.ref_no : [{ required: false }]}
							onChange={handleChange}
							suffix={
								<Tooltip title={"- It is either Cheque No or DD No or Bank Note No. or any reference Transaction No"}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
						/>
					</Col>
				</Row>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_dep_bank_id">
						<InputComponent
							type="select"
							required={!isViewPurpose && paymentData?.bank_flag === 1}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							getPopupContainer={() => document.getElementById('parent_dep_bank_id')}
							label="Bank Name"
							disabled
							name="dep_bank_id"
							placeholder="Select Bank Account"
							rules={(isViewPurpose) ? [] : paymentData?.bank_flag === 1 ? vsmLocation.validationlo.dep_bank_id : [{ required: false }]}
							onChange={(e) => {
								if (depBankData) {
									let selected = depBankData.find(element => element.id === e);
									form.setFieldsValue({
										acc_no: selected?.accno,
									})
								}
								handleChange(e);
							}}
							onFocus={() =>
								!isViewPurpose && depBank &&
								ManageBankAccountHoStore.getBankAccountHoList({ is_active: 1 })
									.then((data) => {
										setDepBankData(data.bank_accounts)
										setDepBank(false);
									})
							}
							notFoundContent={
								(!isViewPurpose && depBank) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: depBankData,
								value_key: "id",
								text_key: "title",
								disabled_keys:
									ManageBankAccountHoStore.dropdown_bank_account_ho_list &&
									ManageBankAccountHoStore.dropdown_bank_account_ho_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					{form.getFieldValue('dep_mode_id') === 2 &&
						<Col xs={{ span: 6 }}>
							<InputComponent
								type="text"
								readOnly={isViewPurpose}
								label="Account No."
								placeholder="Account No."
								name="acc_no"
								disabled
							/>
						</Col>
					}
					{AUTH.user.level_id === Constant.ADMIN_USER && typeMode !== Constant.VIEW &&
						<Col xs={{ span: 6 }}>
							<Button
								key="5"
								onClick={() => {
									props.openAddDepositModal();
								}}
								className="mt-20"
								htmlType="button"
								type="primary"
							>
								Add Deposit
							</Button>
						</Col>
					}
					{AUTH.user.level_id === Constant.ADMIN_USER && AUTH.checkPrivileges("#1110101#") && typeMode !== Constant.VIEW &&
						<Col xs={{ span: 6 }}>
							<Button
								key="5"
								onClick={() => {
									props.openAddSoftwareChrgModal();
								}}
								className="mt-20"
								htmlType="button"
								type="primary"
							>
								Add Software Charge
							</Button>
						</Col>
					}
				</Row>
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="date"
							format={Constant.DEFAULT_DATE_FORMAT}
							onChange={handleChange}
							disabled={ typeMode === Constant.VIEW}
							label="Dispatch Date"
							placeholder="Dispatch Date"
							name="disp_date"
							disabledDate={disabledDispDate}
							rules={vsmLocation.validationlo.ack_date}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="date"
							format={Constant.DEFAULT_DATE_FORMAT}
							onChange={handleChange}
							disabled={ typeMode === Constant.VIEW}
							label="Acknowledge Date"
							placeholder="Acknowledge Date"
							name="ack_date"
							disabledDate={disabledAckDate}
							rules={vsmLocation.validationlo.ack_date}
						/>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
});

export default LegalOperationsComponent;
