import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import Axios from "axios";
import { convertTextToID } from "../../../../utils/GlobalFunction";
import moment from "moment";
import { Constant } from "../../../../config/Constant";

export default class ManageAccountExpenseStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	advance_filter = null;
	dropdown_expense_head_list = null;
	approveRejectValue = null;
	selectedRow = null;
	viewValues = null;
	addPaymentValues = null;
	deleteValues = null;
	listPurpose = 10;
	dropdown_status_list = null;
	paymentDetails = null
	cancelPaymentValues = null
	visibleRowCount = 0;
	selectedRowCount = 0;
	isSelectAll = false;

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		// set default current date
		this.agGrid.api.setFilterModel({
			entry_sr_date: {
				dateFrom: moment().format("YYYY-MM-DD"),
				dateTo: moment().format("YYYY-MM-DD"),
				type: "inRange",
				filterType: "date",
			},
		});
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to status
	setApproveRejectValue = (data) => {
		this.approveRejectValue = data;
	};

	setSelectedRow = (data) => {
		this.selectedRow = data;
	};

	// set from values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	setAddPaymentValues = (data) => {
		this.addPaymentValues = data;
	};

	setPaymentDetails = (data) => {
		this.paymentDetails = data;
	};

	setCancelPaymentValues = (data) => {
		this.cancelPaymentValues = data
	}

	// set from values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	setStatusList = (data) => {
		this.dropdown_status_list = data;
	};

	//set list purpose
	setListPurpose = (purpose) => {
		this.listPurpose = purpose;
		this.agGrid.api.setFilterModel(null);
		this.agGrid.api.setSortModel(null);
		this.agGrid.columnApi.resetColumnState();
		this.agGrid.api.onFilterChanged(null);
		this.refreshList();
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/expense/acc-expense-manage/list`, payload).then(
			({ data }) => {
				if (data?.list?.data?.length) {
					let startPage = (data?.list?.current_page - 1) * data?.list?.per_page;
					data?.list?.data?.map((item, index) => {
						item.srno = startPage + index + 1;
						if (item.payment_status === Constant.AC_EXPENSE_PAYMENT_STATUS_ID.SUCESSFUL) {
							this.setVisibleRowCount(this.visibleRowCount + 1)
						}
						return null;
					});
				}
				if (this.visibleRowCount > 0 && this.visibleRowCount !== this.selectedRowCount) {
					this.setIsSelectAll(false)
				}
				this.list_data = data?.list ? data?.list?.data : null;
				this.total = data?.list?.total;
				this.current_page = data?.list?.current_page;
				return data;
			}
		);
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.setVisibleRowCount(0)
		this.setSelectedRowCount(0)
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status"]) {
			final_filter["status"].values = convertTextToID(
				final_filter["status"],
				this.dropdown_status_list,
				"name",
				"id"
			);
		}
		return { final_filter, final_sort };
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					purpose: this.listPurpose,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formData) => {
		return Axios.post(`/expense/acc-expense-manage/new`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`/expense/acc-expense-manage/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`/expense/acc-expense-manage/details/${ID}`).then(
			({ data }) => {
				this.setViewValues(data.list);
				return data;
			}
		);
	};

	AddPaymentData = (formData) => {
		return Axios.post(`expense/acc-expense-manage/payment-new`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ViewPaymentData = (Id) => {
		return Axios.get(`expense/acc-expense-manage/payment-detail/${Id}`).then(({ data }) => {
			this.setPaymentDetails(data.view);
			return data;
		});
	};

	setVisibleRowCount = (data) => {
		this.visibleRowCount = data;
	}

	setSelectedRowCount = (data) => {
		this.selectedRowCount = data;
	}

	setIsSelectAll = (selectFlag) => {
		this.isSelectAll = selectFlag;
	}

	CancelPaymentData = (paymentId) => {
		return Axios.get(`/expense/acc-expense-manage/payment-cancel/${paymentId}`)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	onFirstDataRendered = () => {
		if (this.isSelectAll === true && this.agGrid) {
			this.agGrid.api.forEachNode((node) => {
				if (node.data?.payment_status === Constant.AC_EXPENSE_PAYMENT_STATUS_ID.SUCESSFUL) {
					this.setSelectedRowCount(this.selectedRowCount + 1)
					node.setSelected(true);
				}
			});
		}
	};

	onSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstDataRendered();
		} else {
			this.agGrid.api.forEachNode((node) => {
				this.setSelectedRowCount(0);
				node.setSelected(false);
			});
		}
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	//Get List of Expense Head for dropdown
	getExpenseList = (conditional = {}) => {
		return Axios.post(`expense/acc-expense-head/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_expense_head_list = data.lov;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getExpenseStatusList = (conditional = {}) => {
		return Axios.get(`expense/expense-head/status/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_status_list = data.lov;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call approve/reject api
	ApproveData = (formdata) => {
		return Axios.post(`/expense/acc-expense-manage/approve`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
					this.setVisibleRowCount(0);
					this.setSelectedRowCount(0)
					this.setIsSelectAll(false)
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call approve/reject api
	RejectData = (formData, Id) => {
		return Axios.patch("/expense/acc-expense-manage/reject/" + Id, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageAccountExpenseStore, {
	agGrid: observable,
	setupGrid: action,
	per_page: observable,
	setPageSize: action,
	list_data: observable,
	total: observable,
	advance_filter: observable,
	allColumnIds: observable,
	onFilterChanged: action,
	dropdown_expense_head_list: observable,
	onSelectAllRender: action,
	onFirstDataRendered: action,
	approveRejectValue: observable,
	setApproveRejectValue: action,
	selectedRow: observable,
	setSelectedRow: action,
	viewValues: observable,
	setViewValues: action,
	addPaymentValues: observable,
	setAddPaymentValues: action,
	deleteValues: observable,
	setDeleteValues: action,
	setListPurpose: action,
	listPurpose: observable,
	dropdown_status_list: observable,
	getExpenseStatusList: action,
	paymentDetails: observable,
	setPaymentDetails: action,
	cancelPaymentValues: observable,
	setCancelPaymentValues: action,
	setVisibleRowCount: action,
	visibleRowCount: observable,
	setSelectedRowCount: action,
	selectedRowCount: observable,
	setIsSelectAll: action,
	isSelectAll: observable,
});
