import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import LocalGridConfig from "../../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../../utils/GlobalFunction";
import { Constant } from "../../../../../config/Constant";
//import initData from "./../../../../json/init.json";

export default class ManageUnregisteredBagStore {
    detail_filter = null;
    agGridDetail = null;
    per_page_detail = LocalGridConfig.options.paginationPageSize;
    current_page_detail = 1;
    list_data_detail = null;
    total_detail = 0;
    allColumnIdsDetail = [];
    //This is for summary
    summary_filter = null;
    agGridSummary = null;
    per_page_summary = LocalGridConfig.options.paginationPageSize;
    current_page_summary = 1;
    list_data_summary = null;
    total_summary = 0;
    allColumnIdsSummary = [];

    // set form values to  customefilter
    setSummaryFilterValues = (data = null) => {
        return new Promise((resolve) => {
            this.summary_filter = data;
            if (this.agGridSummary) {
                this.setupGridSummary(this.agGridSummary);
            }
            resolve()
        });
    };

    // Setup grid and set column size to autosize
    setupGridSummary = (params) => {
        this.agGridSummary = params;
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeSummary = (page = this.per_page_summary) => {
        this.per_page_summary = page;
        if (this.agGridSummary) {
            this.agGridSummary.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Filter function for no record found message
    onFilterChangedSummary = (params) => {
        this.agGridSummary = params;
        if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() === 0) {
            this.agGridSummary.api.showNoRowsOverlay();
        }
        if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() > 0) {
            this.agGridSummary.api.hideOverlay();
        }
    };

    generatePayload = (summary_filter) => {
        if (summary_filter.from_date) {
            summary_filter.from_date = moment(summary_filter.from_date).format(Constant.PAYLOAD_DATE);
        }
        if (summary_filter.to_date) {
            summary_filter.to_date = moment(summary_filter.to_date).format(Constant.PAYLOAD_DATE);
        }
        return summary_filter;
    }
    
    // call api to get records
    getListSummary = () => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel();
            var sort = this.agGrid.api.getSortModel();
        }
        this.list_data_summary = null;
        return Axios.get(`reports/system-reports/location-login-details`).then(({ data }) => {
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    item.is_active_display =
                        item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
                    return null;
                });
            }
            this.list_data_summary = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            var allColumnIds = [];
            if (this.agGrid && this.agGrid.columnApi) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                });
            }
            if (this.agGrid) {
                this.agGrid.api.setFilterModel(filter);
                this.agGrid.api.setSortModel(sort);
            }
        })
    };
}

decorate(ManageUnregisteredBagStore, {
    per_page_detail: observable,
    agGridDetail: observable,
    list_data_detail: observable,
    total_detail: observable,
    allColumnIdsDetail: observable,
    setSummaryFilterValues: action,
    setupGridDetail: action,
    setPageSizeDetail: action,
    getListDetail: action,
    onFilterChangedDetail: action,
    per_page_summary: observable,
    agGridSummary: observable,
    list_data_summary: observable,
    total_summary: observable,
    allColumnIdsSummary: observable,
    setupGridSummary: action,
    setPageSizeSummary: action,
    getListSummary: action,
    onFilterChangedSummary: action,
});
