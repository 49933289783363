import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import LocalGridConfig from "../../../../../config/LocalGridConfig";
import { Constant } from "../../../../../config/Constant";

export default class ManageEwayOutgoingStatisticsStore {
    summary_filter = null;
    agGridSummary = null;
    per_page_summary = LocalGridConfig.options.paginationPageSize;
    current_page_summary = 1;
    list_data_summary = null;
    total_summary = 0;
    allColumnIdsSummary = [];

    // set form values to  customefilter
    setSummaryFilterValues = (data = null) => {
       return new Promise((resolve) => {
            this.summary_filter = data;
            if (this.agGridSummary) {
                this.setupGridSummary(this.agGridSummary);
            }
            resolve()
        });
    };

    // Setup grid and set column size to autosize
    setupGridSummary = (params) => {
        this.agGridSummary = params;
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeSummary = (page = this.per_page_summary) => {
        this.per_page_summary = page;
        if (this.agGridSummary) {
            this.agGridSummary.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Filter function for no record found message
    onFilterChangedSummary = (params) => {
        this.agGridSummary = params;
        if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() === 0) {
            this.agGridSummary.api.showNoRowsOverlay();
        }
        if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() > 0) {
            this.agGridSummary.api.hideOverlay();
        }
    };

    generatePayload = (summary_filter) => {
        if(summary_filter.from_date){
            summary_filter.from_date = moment(summary_filter.from_date).format(Constant.PAYLOAD_DATE);
        }
        if(summary_filter.to_date){
            summary_filter.to_date = moment(summary_filter.to_date).format(Constant.PAYLOAD_DATE);
        }
        return summary_filter;
    }

    // call api to get records
    getListSummary = () => {
        if (this.agGridSummary) {
            var filter = this.agGridSummary.api.getFilterModel();
            var sort = this.agGridSummary.api.getSortModel();
        }
        this.list_data_summary = null;
        let summary_filter = this.generatePayload(this.summary_filter);
        return Axios.post(`/reports/daily-activities/outgoing-load-with-eway`, {advance_filter: summary_filter}).then(({ data }) => {
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    return null;
                });
            }
            this.list_data_summary = data.list ? data.list.data : null;
            this.total_summary = data.list.data.total_summary;
            this.current_page_summary = data.list.data.current_page;
            var allColumnIdsSummary = [];
            if (this.agGridSummary && this.agGridSummary.columnApi) {
                this.agGridSummary.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIdsSummary.push(column.colId);
                });
            }
            if (this.agGridSummary) {
                this.agGridSummary.api.setFilterModel(filter);
                this.agGridSummary.api.setSortModel(sort);
            }
        });
    };
    doExportDownload = (data) => {
        return Axios.post(`/reports/daily-activities/outgoing-load-with-eway`, { advance_filter: data })
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };
}

decorate(ManageEwayOutgoingStatisticsStore, {
    setSummaryFilterValues:action,
    per_page_summary: observable,
    agGridSummary: observable,
    list_data_summary: observable,
    total_summary: observable,
    allColumnIdsSummary: observable,
    setupGridSummary: action,
    setPageSizeSummary: action,
    getListSummary: action,
    onFilterChangedSummary: action,
});
