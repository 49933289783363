import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../../config/ServerGridConfig";
import moment from "moment";
import { Constant } from "../../../../../config/Constant";

export default class ManageReliveCancelBookingStore {
	detail_filter = null;
	agGridDetail = null;
	per_page_detail = ServerGridConfig.options.paginationPageSize;
	current_page_detail = 1;
	list_data_detail = null;
	total_detail = 0;
	allColumnIdsDetail = [];
	reliveValues = null

	// set form values to  customefilter
	setDetailFilterValues = (data = null) => {
		return new Promise((resolve) => {
			this.detail_filter = data;
			if (this.agGridDetail) {
				this.setupGridDetail(this.agGridDetail);
			}
			resolve();
		});
	};

	setReliveValues = (data) => {
		this.reliveValues = data
	}

	// Setup grid and set column size to autosize
	setupGridDetail = (params) => {
		this.agGridDetail = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeDetail = (page = this.per_page_detail) => {
		this.per_page_detail = page;
		if (this.agGridDetail) {
			this.agGridDetail.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	onFilterChangedDetail = (params) => {
		this.agGridDetail = params;
		if (
			this.agGridDetail &&
			this.agGridDetail.api.getModel().getRowCount() === 0
		) {
			this.agGridDetail.api.showNoRowsOverlay();
		}
		if (
			this.agGridDetail &&
			this.agGridDetail.api.getModel().getRowCount() > 0
		) {
			this.agGridDetail.api.hideOverlay();
		}
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort };
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/reports/mis/traveling-vs-cancel-booking`, payload).then(
			({ data }) => {
				if (data?.list?.data?.length) {
					let startPage = (data.list.current_page - 1) * data.list.per_page;
					data.list.data.map((item, index) => {
						item.srno = startPage + index + 1;
						return null;
					});
				}
				this.list_data_detail = data?.list ? data?.list?.data : null;
				this.total_detail = data?.list?.total;
				this.current_page_detail = data?.list?.current_page;
				return data;
			}
		);
	};

	generatePayload = (filter) => {
		if (filter?.from_date) {
			filter.from_date = moment(filter.from_date).format(Constant.PAYLOAD_DATE);
		}
		if (filter?.to_date) {
			filter.to_date = moment(filter.to_date).format(Constant.PAYLOAD_DATE);
		}
		return filter;
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
					...this.generatePayload(this.detail_filter)
				};
				this.getList(payload).then((data) => {
					if (data?.list?.total === 0) {
						this.agGridDetail.api.showNoRowsOverlay();
					} else {
						this.agGridDetail.api.hideOverlay();
					}
					params.successCallback(data?.list?.data, data?.list?.total);
					var allColumnIds = [];
					if (this.agGridDetail && this.agGridDetail.columnApi && data?.total) {
						this.agGridDetail.columnApi
							.getAllColumns()
							.forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
					}
				});
			},
		};
	};

	refreshList = () => {
		if (this.agGridDetail) {
			this.setupGridDetail(this.agGridDetail);
		}
	};

	// call api to download excel or pdf
	doExportDownload = (data) => {
		return Axios.post(`/reports/mis/traveling-vs-cancel-booking`, data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	
	// call api to relive 
	ReliveData = (formdata) => {
		return Axios.post(`reports/mis/cancel-relive`, formdata)
			.then(({ data }) => {
				if (this.agGridDetail) {
					this.setupGridDetail(this.agGridDetail);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageReliveCancelBookingStore, {
	per_page_detail: observable,
	agGridDetail: observable,
	list_data_detail: observable,
	total_detail: observable,
	allColumnIdsDetail: observable,
	setupGridDetail: action,
	setPageSizeDetail: action,
	getList: action,
	onFilterChangedDetail: action,
	detail_filter: observable,
	setDetailFilterValues: action,
	reliveValues: observable,
	setReliveValues: action,
});
