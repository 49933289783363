export const Constant = {
    RESEND_OTP_TIMER: 60,
    UNDEFINED_VALUES: "N/A",
    ADD: "add",
    EDIT: "edit",
    DUPLICATE: "duplicate",
    VIEW: "view",
    COPY: "copy",
    ALL: "all",
    ADMIN_USER: 101,
    AGENCY_USER: 111,
    FR_USER: 121,
    LOCATION_USER: 131,
    CLIENT_USER: 141,
    WEBSITE_USER: 151,
    DELIVERY_BOY_USER: 131,
    CEPL_USER: 161,
    SERVER_DATE_FORMAT: "YYYY-MM-DD",
    DEFAULT_DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm:ss",
    DEFAULT_DATE_HOUR_TIME_FORMAT: "DD/MM/YYYY HH:mm",
    S3BUCKET_API_BASEURL: (process.env.React_APP_S3bucket) ? process.env.React_APP_S3bucket : "https://felis-cms.s3.ap-south-1.amazonaws.com/",
    DEFAULT_DATE_FORMAT: "DD/MM/YYYY",
    DEFAULT_DISPLAY_DATE_TIME_FORMAT: "DD/MM/YYYY hh:mm a",
    BOOKING_PASS_DEFAULT_DISPLAY_DATE_TIME_FORMAT: "YYYY/MM/DD hh:mm",
    BOOKING_DEFAULT_DISPLAY_DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm",
    DEFAULT_DISPLAY_MONTH_NAME: "Do MMM",
    DEFAULT_MONTH_YEAR_FORMAT: "MM/YYYY",
    DEFAULT_MONTH_FORMAT:"MM",
    DEFAULT_MONTH_NAME_YEAR_FORMAT: "MMM-YYYY",
    DEFAULT_DATE_MONTH_FORMAT: "DD/MM",
    UTCOFFSET: 330,
    HO: "HO",
    DEFAULT_HO_ID: 1,
    HUB: "HUB",
    CENTER: "CENTER",
    LOCATION: "Location",
    FRAINCHAISEE: "FRAINCHAISEE",
    ODA1_ID: 4,
    ODA2_ID: 5,
    COMING_SOON: "Coming Soon...",
    PDFICON: `${process.env.React_APP_S3bucket}branding/pdf_file_icon.svg`,
    DISCOUNTID: 12,
    QRCODEID: 14,
    ROLE_DELIVERY_BOY: "Delivery Boy",
    RATECARD_TEMPLATE_TYPE_CASH: 1,
    RATECARD_TEMPLATE_TYPE_ACCOUNT: 1,
    PRODUCT_BY_SURFACE: 2,
    PRODUCT_BY_AIR: 3,
    PARCEL_PRODUCTS: [2, 3, 18],
    EXPRESS_PRODUCTS: [4, 18],
    WITHOUT_EXPRESS_PRODUCTS: [1, 2, 3],
    EXPRESS_ID: 4, // DOX EXPRESS Product
    EXPRESS_NON_DOX_ID: 18, // DOX EXPRESS Product
    DOCUMENT_ID: 1,
    BOOKING_COUNTRY_ID: 1,
    NON_SERVICEABLE_ID: 1,
    EXPRESSCOVER: 11,
    BAGSTIKER: 14,
    EXPRESSSTIKER: 12,
    PACKET_STICKER: 17,
    NORMAL_BOOKING_ID: 10,
    CASH: 1,
    ACCOUNT: 2,
    EXPRESS: 3,
    BAG: 4,
    BAG_TAG: 7,
    PACKET: 5,
    CROSSING: 6,
    INTERNATIONAL:8,
    ACCOUNTVIRTUALSTIKERS:9,
    MAPURL: "https://www.google.com/maps/search/?api=1&query=",
    TYPE_CON: 1,
    TYPE_BAG: 2,
    TYPE_PCK: 3,
    STIKERACCOUNTBOOKING: 2,
    BAG_NO_NOT: "Not Finalized",
    STIKEREXPRESS: 3,
    CONSIGNMENT: 3,
    IMAGEQUALITY: 0.3,
    MAXWIDTHORHEIGHT: 1024,
    COMPRESS_SIZE: 3, //This is in MB
    STICKERS_FOR_CASH_BOOKING: 7,
    STICKERS_FOR_ACCOUNT_BOOKING: 8,
    COVERS_FOR_EXPRESS_BOOKING: 11,
    STICKERS_FOR_EXPRESS_BOOKING: 12,
    STICKERS_FOR_BAG: 14,
    PER_PAGE_REPORT_COUNT: 50,
    STICKERS_FOR_CASH_BOOKING_B_W: 15,
    STICKERS_FOR_ACCOUNT_BOOKING_B_W: 16,
    STICKERS_FOR_PACKETS: 17,
    INT_DOMAIN_URL: 'https://int.connectingnandan.com/',
    BOOKING_STATUS: {
        MISSING: 101,
        CONFIRMED: 105,
        INTRANSIT: 201,
        OUTFORDELIVERY: 301,
        DELIVERYFAILED: 310,
        DELIVERED: 401,
        RETURNED: 501,
        RECEIVEDBACK: 502,
        RETURNED_CUST: 503,
        CANCLLED: 901,
    },
    BOOKING_TYPE: {
        CASH: "1",
        ACCOUNT: "2",
    },
    TRANSFER_STATUS: {
        MISSING: 101,
        CONFIRMED: 105,
        INTRANSIT: 201,
        OUTFORDELIVERY: 301,
        DELIVERYFAILED: 310,
        DELIVERED: 401,
        CANCELLED: 901,
    },
    SERVICE_NAME: {
        DOX: 1,
        NONDOXBYSURFACE: 2,
        NONDOXBYAIR: 3,
        DOXEXPRESS: 4,
        NONDOXEXPRESS: 18,
    },
    WIDGETFROMDATE: 7,
    TRANSFER_FROMDATE: 31,
    YEARS_FROMDATE: 366,
    ONE_AND_HALF_YEAR_DATE : 548,
    HALF_YEAR :180,
    TRANSFER_FROM_MONTH : 6,
    ZERO: 0,
    WEBTRACKINGURL: "web-tracking",
    MODE_OF_TRANSPORT_ROAD: 1,
    LOCATION_LEGAL_DEPOSITE_DATE: "2015/01/01",
    RATECARD_PURPOSE: {
        1: "Standard",
        2: "Standard Rate Card",
        3: "HO Overhead",
        4: "Franchisee Overhead",
        5: "Franchisee & HO Overhead",
        6: "Fuel Charge Overhead"
    },
    RATECARD_PURPOSE_ID: {
        STANDARD: 1,
        STANDARD_RATE_CARD: 2,
        HO_OVERHEAD: 3,
        FRANCHISEE_OVERHEAD: 4,
        FRANCHISEE_HO_OVERHEAD: 5,
        FUEL_CHRG_OVERHEAD: 6
    },
    RATECARD_STD: 1,
    RATECARD_REG: 2,
    RATECARD_HO: 3,
    RATECARD_FO: 4,
    RATECARD_MO: 5,
    RATECARD_FUEL: 6,
    REPORTS_URL: "/reports",
    PCK: "packet",
    RATE_POLICY: {
        SLAB_ID: 1,
        PIECE_ID: 2,
        HYBRID_ID: 3
    },
    STATE_GUJ: "24",
    AWAITING_VALUES: "Awaiting",
    NOT_AVAILABLE_VALUES: " Not Available",
    FORWARD_BOOKING_NA : 0,
    BOOKING_ONLY: 10,
    INSCAN_FROM_LOCATION: 20,
    INSCAN_FROM_HUB: 30,
    OUTSCAN_TO_LOCATION: 40,
    OUTSCAN_TO_HUB: 50,
    INSCAN: 60,
    REPORTS: {
        GRAND_TOTAL: "Grand Total",
        SUB_TOTAL: "Sub Total"
    },
    BILLINGISSUEDATE: 5,
    CT_ID: 1, //Normal a/c party,
    IS_LOCATION: 1,
    IS_HUB: 1,
    PAYLOAD_DATE: "YYYYMMDD",
    PAYLOAD_DATE_TIME: "YYYYMMDDHHmm",
    TRANSFER_DATE: "2023/06/01",
    BOOKING_INSURANCE: {
        STATE_ID_GUJ: 1,
        CITY_ID_MUM: 1390,
    },
    DRS_STATISTICS_REPORT_TYPE: {
        NORMAL: 1,
        ZERO_INSCAN: 2,
        ZERO_DRS: 3,
    },
    PAYMENT_MODES: {
		CASH: 1,
		CHEQUE: 2,
		RTGS: 3,
		NEFT: 4,
		IMPS: 5,
		NET_BANKING: 6,
		WALLET: 7,
		DD: 8,
		BANK_NOTES: 9,
		CREDIT_CARD: 10,
		DEBIT_CARD: 11,
		DISCOUNT: 12,
		DEPOSIT: 13,
		SCAN_QR: 14,
		CREDIT: 15,
		UPI: 16,
		HO_CREDIT: 17,
		STICKER_CREDIT: 18,
		ZERO_AMOUNT: 19
    },
    BK_INT_KYC_TYPE: {
        AADHAR: 1,
        IEC: 2,
        GST: 3,
        PASSPORT: 4,
        PAN: 5,
    },
    EWAY_PURPOSE: {
        FIRST_MILE: 1,
        MIDDLE_MILE: 2,
        LAST_MILE: 3
    },
    LOCK_MASTER: {
        PENDING_DRS_CLOSURE: 1,
        PENDING_DRS_PAGES: 2,
        MISSING_CONSIGNMENTS_100: 3,
        MISSING_CONSIGNMENTS_200: 4
    },
    OVERHEAD_EXCEPTION_LISTING_PURPOSE: {
        HUB: 1,
        LOCATION: 2
    },
    ALLOW_PINCODE: {
        LOCATION_WISE: 'Location Wise',
        HUB_WISE: 'Hub Wise',
        ALL_INDIA: 'All India',
    },
    LCK_MANUAL_TYPE: {
        MIS_ACCOUNT: 2,
    },
    REPORT_TYPE_OPTION: {
        SUMMARY: 1,
        DETAIL:2
    },
    REPORT_EXPENSE_TYPE_OPTION: {
        DETAIL: 1,
        SUMMARY_BY_HUB: 2,
        SUMMARY_BY_HEAD: 3,
    },
    EXP_DEBIT_CREDIT_ID: {
        DEBIT: 0,
        CREDIT:1
    },
        
    EXP_DEBIT_CREDIT_LABEL: {
        DEBIT :"Debit",
        CREDIT :"Credit"
    },
    EXPENSE_STATUS : {
        PENDING_APPROVAL : 10,
        APPROVE : 20,
        REJECT :30
    },
    UPDATE_BOOKING_STATUS : {
        PENDING_APPROVAL : 10,
        APPROVE : 20,
        REJECT :30
    },
    CENTER_TYPE: {
		BOOKING_AND_DELIVERY: 1,
		BOOKING_ONLY: 2,
		DELIVERY_ONLY: 3
	},
    CLIENT_APPROVAL_STATUS : {
        PENDING_APPROVAL: 10,
        APPROVE: 20,
        REJECT: 30
    },
    FRANCHISEE_APPROVAL_STATUS : {
        PENDING_APPROVAL: 10,
        APPROVE: 20,
        REJECT: 30
    },  
    EXPENSE_HEAD : {
        OPENING : 1,
        CLOSING : 2,
        CREDIT_HO : 3,
        CREDIT_FROM_LOCATION : 4,
        RO_BOOKING : 5,
        CREDIT_FROM_LOCATION_OTHERS : 6,
        CREDIT_OTHERS : 7
    }, 
    BOOKING_TYPE_LABEL :{
        CASH : "Cash",
        ACCOUNT : "Account"
    },
    ACCOUNT_PARTY_BILL_GENERATE : {
        IS_CLIENT_ALL :1,
        IS_CLIENT_BILL_GENERATE_ACCOUNT_PARTY :2
    },
    CON_NO_TYPE_ID : {
        SINGLE : 1,
        MULTIPLE : 2
    },
    AC_EXPENSE_PAYMENT_STATUS: {
        10: "Info Not Available",
        20: "Successful",
        30: "Cancelled"
    },
    AC_EXPENSE_PAYMENT_STATUS_ID: {
        NO_PAYMENT: 10,
        SUCESSFUL: 20,
        CANCELLED: 30
    },
    ADDITIONAL_DETAIL_ID : {
        WITH_ADDITIONAL_CHARGES : 1,
        WITH_OTHER_CHARGES : 2,
        WITH_ADDITIONAL_AND_OTHER_CHARGES : 3
    },
    MOBILE_NUMBER_ID : {
        ALL : 1,
        SENDER : 2,
        RECEIVER : 3
    },
    FROM_DATE : "20200101",
    PAYMENT_PURPOSE : {
        AGENCY : 11
    },
    AGENCY_PAYMENT_REPORT_TYPE : {
        SUMMARY : 1,
        DETAIL : 2,
        VENDOR_DETAIL : 3
    },
    DAILY_BOOKING_REPORT_TYPE_ID :{
        DETAIL_WITH_REMARKS : 6
    },
    RATE_CARD_ACCOUNT_DRAFT_STATUS : {
        DRAFT : 1,
        PENDING_APPROVAL : 2
    },
    INTERNATIONAL_BOOKING_PAYMENT_STATUS : {
        CONFIRMED : 2
    },
    SUMMARY_INFO_OPTION: {
        CREATE_SUMMARY: 1,
        SUMMARY_LISTING:2
    },
    CANCEL_TYPE_OPTION: {
        TRAVELED_BOOKING: 1,
        NORMAL_BOOKING:2
    },
    FRANCHISEE_INQUIRY_STATUS_ID : {
        PENDING: 10,
        QUALIFIED_FOR_DOC_UPLOAD: 20,
        REJECTED: 30,
        PENDING_CUSTOMER: 40,
        FINALIZED: 50,
        DISCUSSION_MODE: 60,
        FORWARD_TO_ZONAL_MANAGER: 70
    },
    FRANCHISEE_INQUIRY_APP_STATUS_ID : {
        APP_SAVE_AS_DRAFT: 10,
        DOC_UPLOAD_BY_APPLICANT: 20,
        READY_FOR_FRANCHISEE_CREATION: 30,
        GENERATE_FRANCHISEE: 40,
        FRANCHISEE_ON_BOARD: 50,
    },
    FOR_HO_HUB_ID : {
        HO : 1,
        HUB : 2
    },
}