import Axios from "axios";
import { action, decorate } from "mobx";

export default class TrackShipmentStore {

	getTrackShipmentData = (formdata) => {
		return Axios.post(
			`/utilities/search/track-old-search`, formdata
		).then(({ data }) => {
			return data.view;
		})
		.catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
					this.trackingErrMsg = notify;
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});
	};
	
}

decorate(TrackShipmentStore, {
	getTrackShipmentData: action
});
