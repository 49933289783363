import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Modal, Row, Spin } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import InputComponent from "../../../../component/InputComponent";
import useStore from "../../../../store";
import debounce from "lodash/debounce";
import { vsmTrackShipment } from "./Validation";
import { Constant } from "../../../../config/Constant";
import { checkStickerSeries } from "../../../../utils/GlobalFunction";
import { Messages } from "../../../../config/messages/messages";
import { vsmNotify } from "../../../../config/messages/common";

const TrackShipment = observer((props) => {
	const{
		setConsignmentTrackingModal,
		SetMessagesText,
		setWarningModal
	}=props
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState(true);
	const [fetchFinancialYear, setFetchFinancialYear] = useState(true);

	const {
		TrackShipmentStore: { getTrackShipmentData },
		ManageFinancialYearsStore,
		AUTH: { checkPrivileges },
		ManageTrackingStore: {  setConsignmentTrackingValues },
	} = useStore();


	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	
	// Handle submit and call function to get tracking consignment
	const handleSubmit = async (value) => {
		
		const dbName = value.fy_id !== undefined && ManageFinancialYearsStore.dropdown_financial_year_list ?
			ManageFinancialYearsStore.dropdown_financial_year_list.find((item) => item.id === value.fy_id).db_name : ""
		let val = value.con_no.toString()
		let fyId = value.fy_id
		const payload = {
			con_no: val,
			fy_id: fyId,
			db_name: dbName,
		}
		if (val && val.length === 13) {
			if (!checkStickerSeries(val)) {
			// if (parseInt(val.substr(6, 1)) === Constant.CASH || parseInt(val.substr(6, 1)) === Constant.ACCOUNT || parseInt(val.substr(6, 1)) === Constant.CROSSING || parseInt(val.substr(6, 1)) === Constant.ACCOUNTVIRTUALSTIKERS || parseInt(val.substr(6, 1)) === Constant.INTERNATIONAL) {
				await getTrackShipmentData(payload).then((data) =>{
					if(data) {
						setConsignmentTrackingValues(data);
						setConsignmentTrackingModal(true);
						close()
					}
				}).catch((error) =>{
					vsmNotify.error({ message: error });
				})
			// }
		}
		else {
			setWarningModal(true)
			SetMessagesText(Messages.INVALID_TRACKING_NUMBER)
		}
		}
		else if (val && val.length === 11) {
			var val_split_con = val.substr(2, 1)
			if (val_split_con && parseInt(val_split_con) === Constant.CONSIGNMENT) {
				if ((checkPrivileges("#1070052#") || checkPrivileges("#1070053#"))) {
				await getTrackShipmentData(payload).then((data) =>{
					if(data) {
						setConsignmentTrackingValues(data);
						setConsignmentTrackingModal(true);
						close()
					}
				}).catch((error) =>{
					vsmNotify.error({ message: error });
				})
				}
				else {
					setWarningModal(true)
					SetMessagesText(Messages.CON_TRACKING_DISABLED)
				}
			}
			else {
				setWarningModal(true)
				SetMessagesText(Messages.INVALID_TRACKING_NUMBER)
			}
		} 
		else {
			setWarningModal(true)
			SetMessagesText(Messages.SHIPMENT_LEN_ERROR)
		}
	}
	
	const close = () => {
		props.close()
		form.resetFields();
		setDisabled(true)
		ManageFinancialYearsStore.dropdown_financial_year_list = null
		setFetchFinancialYear(true)
	}

	return props.visible && (
		<Modal
			className="addModal"
			centered
			title="Track Shipment"
			width={534}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					// loading={saving}
					form="TrackShipmentForm"
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				id="TrackShipmentForm"
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row>
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="select"
							required
							autoComplete="chrome-off"
							label="Financial Year"
							name="fy_id"
							placeholder="Select Financial Year"
							rules={vsmTrackShipment.validation.fy_id}
							onChange={handleChange}
							onFocus={() =>
								fetchFinancialYear &&
								ManageFinancialYearsStore.getFinancialYearList({ purpose: 3 }).then(() =>
									setFetchFinancialYear(false)
								)
							}
							notFoundContent={
								fetchFinancialYear ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageFinancialYearsStore.dropdown_financial_year_list,
								value_key: "id",
								text_key: "financial_year",
								disabled_keys:
									ManageFinancialYearsStore && ManageFinancialYearsStore.dropdown_financial_year_list &&
									ManageFinancialYearsStore.dropdown_financial_year_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
						<InputComponent
							type="text"
							required
							label="Consignment Number"
							placeholder="Consignment Number"
							name="con_no"
							onChange={() => {
								handleChange()
							}
							}
							rules={vsmTrackShipment.validation.con_no}
						/>
					</Col>

				</Row> 
			</Form>
		</Modal>
	);
});

export default TrackShipment;