import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { b64toBlob, convertIntBookingReceiverAddress, convertIntBookingSenderAddress, convertTextToID } from "../../../../utils/GlobalFunction";
//import initData from "./../../../../json/init.json";

export default class ManageInternationalBookingStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	statusValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	getRateValues = null
	dropdown_IntHsnCode_list = null
	listPurpose = 1;
	bank_detail_data = null
	ManageCommonStoreObject = null;
	singlePaymentDetails = null
	isActiveFilterList = null;
	dropdown_TermsOfTrade_list = null	
	walletBal = null
	transactionId = null
	shDocument = null

	constructor(ManagecommonStoreObject) {
		this.ManagecommonStoreObject = ManagecommonStoreObject;
	}

	setShDocument = (data) => {
		this.shDocument = data;
	}
	
	setTransactionId = (data) => {
		this.transactionId = data;
	}
	
	setWalletBal = (data) => {
		this.walletBal = data;
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}
	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertIntBookingSenderAddress(data);
			data.receiverAddress = convertIntBookingReceiverAddress(data);
		}
		this.viewValues = data;
	};

	// set form values to view
	setGetRateValues = (data) => {
		this.getRateValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;

		let datasource = this.createDatasource(ServerGridConfig.options);

		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	//set list purpose
	setListPurpose = (purpose) => {
		this.listPurpose = purpose;
		this.agGrid.api.setFilterModel(null);
		this.agGrid.api.setSortModel(null);
		this.agGrid.columnApi.resetColumnState();
		this.agGrid.api.onFilterChanged(null);
		this.refreshList();
	};

	setTypeMode = (mode) => {
		this.typeMode = mode;
	};

	setPaymentDetails = (data) => {
		this.paymentDetails = data;
		return true;
	};
	setSinglepaymentDetails = (data) => {
		this.singlePaymentDetails = data;
		return true;
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/international/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}			
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["int_bookings.shipment_type_id"]) {
			final_filter['int_bookings.shipment_type_id'].values = convertTextToID(final_filter['int_bookings.shipment_type_id'], this.ManagecommonStoreObject.dropdown_intShipmentTypes_list, 'name', 'id')
		}
		if (final_filter["int_bookings.is_deleted"]) {
			final_filter['int_bookings.is_deleted'].values = convertTextToID(final_filter['int_bookings.is_deleted'], this.isActiveFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};


	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				// var advance_filter = this.setAdvanceFilter();
				var payload = {
					// advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/international/int-booking-save`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify,data });
			});
	};

	// Call edit api
	EditData = (ID, formdata) => {
		return Axios.post(`/international/int-booking-edit/` + ID, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify,data });
			});
	};
	// call api to get cash
	ViewData = (ID) => {
		return Axios.get(`international/int-booking-details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		}).catch(() => {
			return Promise.reject();
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`/international/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	getIntRateData = (formData) => {
		return Axios.post(`international/get-rates`, formData).then(({ data }) => {
			// let data = initData;
			this.setWalletBal(data.wallet_bal);
			this.setGetRateValues(data.rates);
			return data;
		})
		.catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});
	};

	//Get List of International Kyc Types for dropdown
	getIntHsnCodeList = (conditional = {}) => {
		return Axios.post(`admin/int-hsn-code/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_IntHsnCode_list = data.IntHsnCode;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Send OTP to sender number for DHL booking verification
	sendDHLOtp = (conditional = {}) => {
		return Axios.post(`international/int-send-otp`, conditional)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Verify OTP for DHL booking verification
	verifyDHLOtp = (conditional = {}) => {
		return Axios.post(`international/int-verify-otp`, conditional)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	doPrintReceipt = (data) => {
        return Axios.post(`/international/print-receipt`, data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

	BankDetails = () => {
        return Axios.get(`international/bank-details`)
            .then(({ data }) => {
                this.bank_detail_data = data.bankDetails;
                return data
            })
            .catch((response) => {
                return Promise.reject(response);
            })
    }

	AddPaymentData = (formdata) => {
		return Axios.post(`international/payment/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ViewPaymentData = (ID) => {
		return Axios.get(`international/int-payment-details/${ID}`).then(({ data }) => {
			// let setData = data.view;
			// if (this.paymentDetails) {
			// 	let oldval = this.paymentDetails;
			// 	setData = { ...oldval, ...data.view };
			// }
			this.setPaymentDetails(data.view);
			return data;
		});
	};

	BookingApproveData = (formdata) => {
		return Axios.post("international/int-booking-confirm/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditPaymentRefData = (ID,formdata) => {
		return Axios.post(`international/payment/edit/${ID}`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	invoiceData = (ID) => {
		return Axios.get(`/international/int-booking-invoice-print/${ID}`)
			.then(({ data }) => {
				const file = b64toBlob(data.print, "application/pdf");
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				//Open the URL on new Window
				window.open(fileURL);
				//pdfWindow.location.href = fileURL
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};


	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			if (!['id', 'kyc1_front_image','kyc1_back_image','kyc2_front_image','box_products', 'boxes_details','order_currier_date', 'invoice_date','sh_weight'].includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			}
		})
		return formData
	}
	//Get List of International Kyc Types for dropdown
	getTermsOfTradeList = (conditional = {}) => {
		return Axios.post(`admin/int-temrs-of-trade/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_TermsOfTrade_list = data.intTermsOfTrade;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	
}

decorate(ManageInternationalBookingStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	dropdown_city_list: observable,
	setupGrid: action,
	setStatusValues: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	setGetRateValues : action,
	getRateValues:observable,
	getIntHsnCodeList : action,
	dropdown_IntHsnCode_list:observable,
	setListPurpose: action,
	listPurpose: observable,
	typeMode: observable,
	setTypeMode: action,
	setPaymentDetails: action,
	paymentDetails: observable,
	BankDetails: action,
    bank_detail_data: observable,
	setSinglepaymentDetails: action,
	singlePaymentDetails: observable,
	getTermsOfTradeList:action,
	dropdown_TermsOfTrade_list:observable,
	setWalletBal:action,
	walletBal:observable,
	setTransactionId: action,
	transactionId: observable,
	setShDocument: action,
	shDocument: observable
});
