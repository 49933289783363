import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import { Constant } from "../../../../../config/Constant";
import LocalGridConfig from "../../../../../config/LocalGridConfig";

export default class ManageClientBusinessInfoStore {
	filter = null;
	agGrid = null;
	list_data = null;
	allColumnIds = [];
	dropdown_business_range_list = null
	//This is for detail
	detail_filter = null;
	agGridDetail = null;
	per_page_detail = LocalGridConfig.options.paginationPageSize;
	list_data_detail = null;
	allColumnIdsDetail = [];

	// set form values to  customefilter
	setFilterValues = (data = null) => {
		return new Promise((resolve) => {
			this.filter = data;
			if (this.agGrid) {
				this.setupGrid(this.agGrid);
			}
			resolve();
		});
	};

	// set form values to  customefilter
	setDetailFilterValues = (data = null) => {
		return new Promise((resolve) => {
			this.detail_filter = data;
			if (this.agGridDetail) {
				this.setupGridDetail(this.agGridDetail);
			}
			resolve()
		});
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// Setup grid and set column size to autosize
	setupGridDetail = (params) => {
		this.agGridDetail = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeDetail = (page = this.per_page_detail) => {
		this.per_page_detail = page;
		if (this.agGridDetail) {
			this.agGridDetail.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (
			this.agGrid &&
			this.agGrid.api.getModel().getRowCount() === 0
		) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (
			this.agGrid &&
			this.agGrid.api.getModel().getRowCount() > 0
		) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Filter function for no record found message
	onFilterChangedDetail = (params) => {
		this.agGridDetail = params;
		if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() === 0) {
			this.agGridDetail.api.showNoRowsOverlay();
		}
		if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() > 0) {
			this.agGridDetail.api.hideOverlay();
		}
	};

	// call api to get records
	getList = () => {
		this.list_data = null;
		let filters = this.generatePayload(this.filter);
		return Axios.post(`/reports/mis/client-business-summary`, {
			...filters,
		}).then(({ data }) => {
			if (data?.list?.data?.length) {
				data?.list?.data.map((item, index) => {
					item.srno = index + 1;
					return null;
				});
			}
			this.list_data = data?.list ? data?.list?.data : null;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
		});
	};

	// call api to get records
	getListDetail = () => {
		if (this.agGridDetail) {
			var filter = this.agGridDetail.api.getFilterModel();
			var sort = this.agGridDetail.api.getSortModel();
		}
		this.list_data_detail = null;
		let detail_filter = this.generatePayload(this.detail_filter);
		return Axios.post(`reports/mis/client-business-details`, { ...detail_filter }).then(({ data }) => {

			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					return null;
				});
			}
			this.list_data_detail = data.list ? data.list.data : null;
			var allColumnIdsDetail = [];
			if (this.agGridDetail && this.agGridDetail.columnApi) {
				this.agGridDetail.columnApi.getAllColumns().forEach(function (column) {
					allColumnIdsDetail.push(column.colId);
				});
			}
			if (this.agGridDetail) {
				this.agGridDetail.api.setFilterModel(filter);
				this.agGridDetail.api.setSortModel(sort);
			}
		});
	};

	generatePayload = (summary_filter) => {
		if (summary_filter.from_date) {
			summary_filter.from_date = moment(summary_filter.from_date).format(
				Constant.PAYLOAD_DATE
			);
		}
		if (summary_filter.to_date) {
			summary_filter.to_date = moment(summary_filter.to_date).format(
				Constant.PAYLOAD_DATE
			);
		}
		return summary_filter;
	};

	getBusinessRangeList = (conditional = {}) => {
		return Axios.post(`/bookings/account/business-info-range/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_business_range_list = data.lov;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	doExportDownload = (data) => {
		return Axios.post(`/reports/mis/client-business-summary`, {
			...data,
		})
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	doExportDownloadDetail = (data) => {
		return Axios.post(`reports/mis/client-business-details`, {
			...data,
		})
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageClientBusinessInfoStore, {
	agGrid: observable,
	list_data: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	dropdown_business_range_list: observable,
	per_page_detail: observable,
	agGridDetail: observable,
	list_data_detail: observable,
	allColumnIdsDetail: observable,
	setupGridDetail: action,
	setPageSizeDetail: action,
	getListDetail: action,
	onFilterChangedDetail: action,
	setDetailFilterValues: action,
});
