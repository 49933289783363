import { Suspense, useEffect } from "react";
import { observer } from "mobx-react";
import useStore from "./store";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./utils/AppRouter";
import Login from "./page/Login";
import LayoutComponent from "./component/LayoutComponent";
import Fullscreenspinner from "./page/SpinnerPage";
import { getTokenInit } from "./firebase";

const getFaviconEl = () => {
	return document.getElementById("favicon");
}

const App = observer(() => {
	const { 
		AUTH,
		AUTH: { 
			user,
			company,
			setAxiosInterceptors,
			setUnreadNotificationCount,
			unreadNotificationCount
		}
	} = useStore();

	useEffect(() => {
		setAxiosInterceptors();
	}, [setAxiosInterceptors]);

	useEffect(() => {
		async function setupFirebase() {
			await getTokenInit(setUnreadNotificationCount, unreadNotificationCount);
		}

		setupFirebase();
		getSystemInfo();
		// eslint-disable-next-line
	}, [user, setUnreadNotificationCount, unreadNotificationCount]);


	useEffect(() => {
		const favicon = getFaviconEl();
		favicon.href = company && company.branding.favicon;;
	}, [company]);

	const handleStorageEvents = (e) => {
		if (e.key === 'token') {
			if (e.oldValue && !e.newValue) {
				AUTH.resetAuthUser();
				window.location.href = "/";
			} else if (!e.oldValue && e.newValue) {
				window.location.reload();
			}
		}
	}

	const getSystemInfo = () => {
		if (window.ipcRenderer) {
			window.ipcRenderer.on('setSystemInfo', function (event, data) {
				localStorage.setItem("UUID", data.UUID);
				localStorage.setItem("PLATFORMOS", data.platform);
			});
			window.ipcRenderer.on('updateLoader', function (event, data) {
				AUTH.loading = data.loading;
			});
		}
	}

	window.addEventListener("storage", handleStorageEvents);

	return (
		<BrowserRouter basename="/">
			<Suspense fallback={<div className="fullscreen__spinner">
				<Fullscreenspinner /></div>}>
				{AUTH.open_login ?
					<Login /> :
					<LayoutComponent>
						<AppRouter />
					</LayoutComponent>
				}
			</Suspense>
		</BrowserRouter>
	);
});

export default App;
