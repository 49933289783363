import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Col, Layout, Row, Image, Timeline, Table, Modal, Collapse, Empty, Alert, Form } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../store";
import { Constant } from "../../../config/Constant";
import { vsmCommon, vsmNotify, } from "../../../config/messages/common";
import { consignmentTagStatus, convertDate, replaceString } from "../../../utils/GlobalFunction";
import moment from "moment";
import { PrintIcon } from "../../../config/IconsConfig";
import InputComponent from "../../../component/InputComponent";
import { Patterns } from "../../../config/messages/patterns";
import { Messages } from "../../../config/messages/messages";

const WrongSortingcolumns = [
	{
		title: 'Entry Date',
		dataIndex: 'entry_date',
		width: '8%',
		render: (entry_date) => {
			return (
				<span>{convertDate(entry_date, Constant.DEFAULT_DATE_FORMAT)}</span>
			)
		},
	},
	{
		title: 'Reported By',
		dataIndex: 'org_name',
		width: '20%',
		render: (org_name) => {
			return (
				<span>{org_name ? org_name : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Received From',
		dataIndex: 'from_name',
		width: '20%',
		render: (from_name) => {
			return (
				<span>{from_name ? from_name : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Remarks',
		dataIndex: 'remarks',
		width: '16%',
		render: (remarks) => {
			return (
				<span style={{ wordBreak: 'break-word' }}>{remarks ? remarks : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
];
const LostShipmentcolumns = [
	{
		title: 'Entry Date',
		dataIndex: 'entry_date',
		width: '8%',
		render: (entry_date) => {
			return (
				<span>{convertDate(entry_date, Constant.DEFAULT_DATE_FORMAT)}</span>
			)
		},
	},
	{
		title: 'Reported By',
		dataIndex: 'org_name',
		width: '20%',
		render: (org_name) => {
			return (
				<span>{org_name ? org_name : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Received From',
		dataIndex: 'from_name',
		width: '20%',
		render: (from_name) => {
			return (
				<span>{from_name ? from_name : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Remarks',
		dataIndex: 'remarks',
		width: '16%',
		render: (remarks) => {
			return (
				<span style={{ wordBreak: 'break-word' }}>{remarks ? remarks : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
];
const ReturnToOrigincolumns = [
	{
		title: 'Entry Date',
		dataIndex: 'entry_date',
		width: '8%',
		render: (entry_date) => {
			return (
				<span>{convertDate(entry_date, Constant.DEFAULT_DATE_FORMAT)}</span>
			)
		},
	},
	{
		title: 'Reported By',
		dataIndex: 'org_name',
		width: '20%',
		render: (org_name) => {
			return (
				<span>{org_name ? org_name : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Received From',
		dataIndex: 'from_name',
		width: '15%',
		render: (from_name) => {
			return (
				<span>{from_name ? from_name : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Remarks',
		dataIndex: 'remarks',
		width: '20%',
		render: (remarks) => {
			return (
				<span style={{ wordBreak: 'break-word' }}>{remarks ? remarks : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Is Received?',
		dataIndex: 'is_received',
		width: '16%',
		render: (is_received) => {
			return (
				<span>{is_received ? is_received : Constant.UNDEFINED_VALUES}</span>
			)
		},
	}
];
const DeliveryInformationcolumns = [
	{
		title: 'Delivery Time',
		dataIndex: 'created',
		width: '5%',
		render: (created) => {
			return (
				<span>{convertDate(created, Constant.DEFAULT_DATE_FORMAT)}</span>
			)
		},
	},
	{
		title: 'Delivery Man',
		dataIndex: 'delivery_boy',
		width: '7%',
		render: (delivery_boy) => {
			return (
				<span>{delivery_boy ? delivery_boy : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Area',
		dataIndex: 'area',
		width: '7%',
		render: (area) => {
			return (
				<span>{area ? area : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Reason',
		dataIndex: 'reason',
		width: '16%',
		render: (reason) => {
			return (
				<span style={{ wordBreak: 'break-word' }}>{reason ? reason : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
];
const ConsignmentTrackingComponent = observer((props) => {
	const [form] = Form.useForm();
	const { opencketTrackingModal, openBagTrackingModal } = props;
	const {
		ManageTrackingStore: { consignmentTrackingValues, getConsignmentTrackingData, trackingErrMsg, setTrackingErrMsg, setConsignmentTrackingValues,sendEmailTrack },
		ManageTrackingStore
	} = useStore();
	const { Panel } = Collapse;
	const [deliveryViewDetailModel, setdeliveryViewDetailModel] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [defaultActive, setdefaultActive] = useState(["2", "3", "7"])
	const [isModalOpen, setIsModalOpen] = useState(false);
	// const [disabledSave, setDisabledSave] = useState(true);
	// reset form and close add form
	const close = () => {
		props.close();
		setdefaultActive(["2", "3", "7"]);
		setTrackingErrMsg(null)
		setConsignmentTrackingValues(null)
	};
	// const deliveryViewDetailModelopen = () => {
	// 	setdeliveryViewDetailModel(true)
	// }

	const onChange = (key) => {
		setdefaultActive(key)
		//Wrong Sorting
		if (!('wrong_sortings' in consignmentTrackingValues) && key.includes('4')) {
			getConsignmentTrackingData({ con_no: consignmentTrackingValues.con_no, purpose: 4 }).then((data) => {
				ManageTrackingStore.consignmentTrackingValues = ({ ...consignmentTrackingValues, ...data });
			})
		}
		//Lost Shipment
		if (!('lost_shipments' in consignmentTrackingValues) && key.includes('5')) {
			getConsignmentTrackingData({ con_no: consignmentTrackingValues.con_no, purpose: 5 }).then((data) => {
				ManageTrackingStore.consignmentTrackingValues = ({ ...consignmentTrackingValues, ...data });
			})
		}
		//Return To Origin
		if (!('rto' in consignmentTrackingValues) && key.includes('6')) {
			getConsignmentTrackingData({ con_no: consignmentTrackingValues.con_no, purpose: 6 }).then((data) => {
				ManageTrackingStore.consignmentTrackingValues = ({ ...consignmentTrackingValues, ...data });
			})
		}
	};
	const PrintPod = async (doc_file) => {
		const imgWindow = window.open();
		const img = document.createElement("img");
		img.src = Constant.S3BUCKET_API_BASEURL + doc_file;
		if (imgWindow) {
			img.style.width = "100%";
			imgWindow.document.write('<img style="width:100%" src="' + img.src + '" onload="window.print();window.close()" />');
			imgWindow.focus();
		}
	}
	

	const handleSendEmail = () => {
		setIsModalOpen(true);
	};
	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
	};

	// const handleChange = () => {
	// 	form
	// 		.validateFields()
	// 		.then(() => {
	// 			setDisabledSave(false);
	// 		})
	// 		.catch(() => {
	// 			setDisabledSave(true);
	// 		});
	// };
	const onFinish = async (data) => {
		setExportLoading(true);
		sendEmailTrack({
			purpose: "1",
			con_no: consignmentTrackingValues.con_no,
			email: data.email

		}).then((data) => {
			// setDisabledSave(false);
			vsmNotify.success({ message: data?.STATUS?.NOTIFICATION?.[0] });
			form.resetFields();
			setExportLoading(false);
			setIsModalOpen(false);
			// setDisabledSave(true);
		}).catch((error) => {
			vsmNotify.error({ message: error?.STATUS?.NOTIFICATION?.[0] });
			form.resetFields();

		})
	}
	// const PrintPod = () => {
	// 	setExportLoading(true);
	// 	const filter = {
	// 		purpose: "2",
	// 		con_no: consignmentTrackingValues.con_no,
	// 	}
	// 	sendEmailTrack(filter)
	// 		.then((data) => {
	// 			const file = b64toBlob(data.view, "application/pdf");
	// 			const fileURL = URL.createObjectURL(file);
	// 			window.open(fileURL, '_blank');
	// 		})
	// 		.catch((e) => {
	// 			if (e.errors && e.errors.length > 0) {
	// 				let errorMessage = e.errors[0].errors.join(", ");
	// 				vsmNotify.error({
	// 					message: errorMessage,
	// 				});
	// 			}
	// 		}).finally(() => setExportLoading(false))
	// }
	return (
		(
			<>
				<Drawer
					className="addModal consignmentTracking"
					title={
						trackingErrMsg ? (
							<>
								Consignment Tracking
							</>
						) :(
						<>
							Consignment Tracking {consignmentTrackingValues?.con_no ? '- ' + consignmentTrackingValues.con_no : ''} {consignmentTagStatus(consignmentTrackingValues?.booking?.bk_status_id)} {consignmentTrackingValues?.booking?.bk_deleted === 1 ? consignmentTagStatus(Constant.BOOKING_STATUS.CANCLLED) : ''}
						</>)
					}
					width="70%"
					visible={props.visible}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onClose={close}
					zIndex={1001}
					footer={[
						<Button
							key="2"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={close}
						>
							Cancel
						</Button>,
					]}
				>
					<>

						{
							trackingErrMsg ? 	
							<Row gutter={30}>
								<Col xs={{ span: 24 }}>
									<Alert
										message={trackingErrMsg}
										type="error"
									/>
								</Col>
							</Row>
							:
							<Collapse
							//accordion
							expandIconPosition={"end"}
							onChange={onChange}
							activeKey={defaultActive}
							className="collapse_new_design"
						>

							<Panel header= {`Booking Information${consignmentTrackingValues?.booking ? ' - '+(consignmentTrackingValues?.con_no) : ''}`} key="2" >
								{consignmentTrackingValues?.booking ? (
									
									<Layout className="details_table">
										<Row gutter={6} wrap={false}>
											<Col span={5} className="form__input__textbox">
												<label>Booking Location : </label>
												<span>{(consignmentTrackingValues?.booking?.center) ? (consignmentTrackingValues?.booking?.center): Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Location's Phone : </label>
												<span>{(consignmentTrackingValues?.booking?.center_mobile) ? (consignmentTrackingValues?.booking?.center_mobile) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Booking Date : </label>
												<span>{(consignmentTrackingValues?.booking?.bk_date) ? ((moment(consignmentTrackingValues?.booking?.bk_date).isValid()) ? convertDate(consignmentTrackingValues?.booking?.bk_date, Constant.DEFAULT_DISPLAY_DATE_TIME_FORMAT) : consignmentTrackingValues?.booking?.bk_date) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Consignment No. : </label>
												<span>{(consignmentTrackingValues?.booking?.con_no) ? (consignmentTrackingValues?.booking?.con_no) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Consignment Type : </label>
												<span>
													{
														(consignmentTrackingValues?.booking?.product) ? (consignmentTrackingValues?.booking?.product):(consignmentTrackingValues?.delivery_details?.length>0) ?
												 			(consignmentTrackingValues?.delivery_details[consignmentTrackingValues?.delivery_details?.length -1]?.product)
												 		: Constant.UNDEFINED_VALUES
													 }
												 </span>
											</Col>
										</Row>
										{consignmentTrackingValues?.view_type === "detail" && <Row gutter={6} wrap={false}>
											<Col span={5} className="form__input__textbox">
												<label>Booking Type : </label>
												<span>{(consignmentTrackingValues?.booking?.catg) ? (consignmentTrackingValues?.booking?.catg) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>From Pincode : </label>
												<span>{(consignmentTrackingValues?.booking?.from_pincode) ? (consignmentTrackingValues?.booking?.from_pincode) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>To Pincode : </label>
												<span>
													{
														(consignmentTrackingValues?.booking?.to_pincode) ? (consignmentTrackingValues?.booking?.to_pincode):(consignmentTrackingValues?.delivery_details?.length>0) ?
												 			(consignmentTrackingValues?.delivery_details[consignmentTrackingValues?.delivery_details?.length -1]?.to_pincode)
												 		: Constant.UNDEFINED_VALUES
													}
												</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Destination Hub : </label>
												<span>
													{(consignmentTrackingValues?.booking?.to_hub) ? (consignmentTrackingValues?.booking?.to_hub):
														(consignmentTrackingValues?.delivery_details?.length>0) ?
												 			(consignmentTrackingValues?.delivery_details[consignmentTrackingValues?.delivery_details?.length -1]?.reg_office)
												 		: Constant.UNDEFINED_VALUES
														}
												</span>
											</Col>
										</Row>}
										<Row gutter={6} wrap={false}>
											{/* <Col span={5} className="form__input__textbox">
												<label>Consignor : </label>
												<span>{(consignmentTrackingValues?.booking?.s_name) ? (consignmentTrackingValues?.booking?.s_name) : Constant.UNDEFINED_VALUES}</span>
											</Col> */}
											<Col span={5} className="form__input__textbox">
												<label>Consignee : </label>
												<span>
													{(consignmentTrackingValues?.booking?.r_name) ? (consignmentTrackingValues?.booking?.r_name) :
														(consignmentTrackingValues?.delivery_details?.length>0) ?
												 			(consignmentTrackingValues?.delivery_details[consignmentTrackingValues?.delivery_details?.length -1]?.received_by)
												 		: Constant.UNDEFINED_VALUES
													}
												</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Destination : </label>
												<span>
													{(consignmentTrackingValues?.booking?.r_city) ? (consignmentTrackingValues?.booking?.r_city)  :
														(consignmentTrackingValues?.delivery_details?.length>0) ?
												 			(consignmentTrackingValues?.delivery_details[consignmentTrackingValues?.delivery_details?.length -1]?.r_city)
												 		: Constant.UNDEFINED_VALUES
														}
												</span>
											</Col>
											{/* {consignmentTrackingValues?.view_type === "detail" && <><Col span={5} className="form__input__textbox">
												<label>Consignor's Phone : </label>
												<span>{(consignmentTrackingValues?.booking?.s_mobile) ? (consignmentTrackingValues?.booking?.s_mobile) : Constant.UNDEFINED_VALUES}</span>
											</Col>
												<Col span={5} className="form__input__textbox">
													<label>Consignee's Phone : </label>
													<span>{(consignmentTrackingValues?.booking?.r_mobile) ? (consignmentTrackingValues?.booking?.r_mobile) : Constant.UNDEFINED_VALUES}</span>
												</Col></>} */}
										</Row>
									</Layout>) :
									(<Layout className="details_table">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Booking information is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Booking information is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>)}
							</Panel>
							<Panel header={`Traveling Information${consignmentTrackingValues?.traveling_info?.length > 0 ? ' - '+(consignmentTrackingValues?.con_no) : ''}`} key="7">
								{consignmentTrackingValues?.traveling_info?.length > 0 && consignmentTrackingValues?.view_type === "detail" ?
									<>
										{/* <h3>Traveling Information</h3> */}
										<div className="details_table_timeline mt-5">
											<Timeline mode={"left"}>
												{consignmentTrackingValues?.traveling_info.map(item => {
													return (<Timeline.Item label={convertDate(item.created, Constant.DEFAULT_DISPLAY_DATE_TIME_FORMAT)}>{item.result}{parseInt(item.parent_ti_id) === Constant.TYPE_PCK ? <span className="text-link-look"
														onClick={() => { opencketTrackingModal(item) }}
													>(Packet - {item.parent_item_no})</span> : ""}{parseInt(item.parent_ti_id) === Constant.TYPE_BAG ? <span className="text-link-look"
														onClick={() => { openBagTrackingModal(item) }}
													>(Bag - {item.parent_item_no})</span> : ""}</Timeline.Item>)
												}
												)}
											</Timeline>
										</div></> : <Layout className="details_table mb-15">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Booking information is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Traveling Information is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>}
							</Panel>
							<Panel  header={`Delivery Information${(consignmentTrackingValues?.delivery_details || consignmentTrackingValues?.drs) ? ' - '+(consignmentTrackingValues?.con_no || '') : '' }`}  key="3">
								{consignmentTrackingValues?.drs || consignmentTrackingValues?.delivery_details ? (
									<Layout className="details_table">
										{/* <Row gutter={6} wrap={false}>
											<Col span={5} className="form__input__textbox">
												<label>Delivery Location : </label>
												<span>{(consignmentTrackingValues?.delivery_details?.center) ? (consignmentTrackingValues?.delivery_details?.center) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Location's Phone : </label>
												<span>{(consignmentTrackingValues?.delivery_details?.center_mobile) ? (consignmentTrackingValues?.delivery_details?.center_mobile) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Regional Office : </label>
												<span>{(consignmentTrackingValues?.delivery_details?.reg_office) ? (consignmentTrackingValues?.delivery_details?.reg_office) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Delivery Area : </label>
												<span>{(consignmentTrackingValues?.delivery_details?.area) ? (consignmentTrackingValues?.delivery_details?.area) : Constant.UNDEFINED_VALUES}</span>
											</Col>
										</Row> */}
										{consignmentTrackingValues?.delivery_details?.map((item, index) => {
											return (
												<div className="deliveryDetailsTableWeb">
													<Row gutter={6} wrap={false}>
													<Col span={5} className="form__input__textbox">
												<label>Delivery Location : </label>
												<span>{(item?.center) ? (item?.center) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Location's Phone : </label>
												<span>{(item?.center_mobile) ? (item?.center_mobile) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Regional Office : </label>
												<span>{(item?.reg_office) ? (item?.reg_office) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Delivery Area : </label>
												<span>{(item?.area) ? (item?.area) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											<Col span={5} className="form__input__textbox">
												<label>Delivery Boy : </label>
												<span>{(item?.delivery_boy) ? (item?.delivery_boy) : Constant.UNDEFINED_VALUES}</span>
											</Col>
											</Row>
											<Row gutter={6} wrap={false}>	
														<Col span={5} className="form__input__textbox">
															<label>Drs Date :</label>
															<span>{(item?.drs_date) ? (item?.drs_date) : Constant.UNDEFINED_VALUES}</span>
														</Col>
														<Col span={5} className="form__input__textbox">
															<label>Delivery Status : </label>
															<span>{(item?.delivery_status) ? (item?.delivery_status) : Constant.UNDEFINED_VALUES}</span>
														</Col>
														<Col span={5} className="form__input__textbox">
															<label>Delivery Date : </label>
															<span>{(item?.delivery_date) ? item?.delivery_date : Constant.AWAITING_VALUES}</span>
														</Col>
														{item?.reason &&
															<Col span={5} className="form__input__textbox">
																<label>Delivery Return Reason : </label>
																<span>{(item?.reason) ? item.reason : Constant.UNDEFINED_VALUES}</span>
															</Col>
														}
													</Row>
													<Row gutter={6} wrap={false}>														
														<Col span={5} className="form__input__textbox">
															<label>Consignee : </label>
															<span>{(item?.received_by) ? (item?.received_by) : Constant.UNDEFINED_VALUES}</span>
														</Col>
														<Col span={5} className="form__input__textbox">
															<label>Confirmation Mode : </label>
															<span>{(item?.confirmation_mode) ? (item?.confirmation_mode) : Constant.UNDEFINED_VALUES}</span>
														</Col>
														{/* <Col span={5} className="form__input__textbox">
															<label>Receiver's Mobile : </label>
															<span>{(item?.receiver_mobile) ? (item?.receiver_mobile) : Constant.UNDEFINED_VALUES}</span>
														</Col> */}
													</Row>
													{/* {(item?.signature || item?.doc_file) && <Row gutter={6} wrap={false}>
														{item?.signature && <Col span={5} className="form__input__textbox">
															<a target={"_blank"} rel="noreferrer"
																href={Constant.S3BUCKET_API_BASEURL + item?.signature}
															>
																View Signature
															</a>
														</Col>}
														{item?.doc_file && <Col span={5} className="form__input__textbox">
															<a target={"_blank"} rel="noreferrer"
																href={Constant.S3BUCKET_API_BASEURL + item?.doc_file}
															>
																PODs
															</a>
														</Col>}
													</Row>} */}
													{item?.signature &&
														<>
															<Row gutter={6} wrap={false} style={{ borderBottom: 0, paddingBottom: 0 }}>
																<label>Signature : </label>
															</Row>
															<Row gutter={6} wrap={false}>
																<Col className="form__input__textbox"> <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.signature
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.signature
																			}`,
																	}}
																	height={100}
																	width={100}
																/>
																</Col>
															</Row>
														</>
													}
													{item?.doc_file &&
														<>
															<Row gutter={6} wrap={false} style={{ borderBottom: 0, paddingBottom: 0 }}>
																<label>PODs : </label>
															</Row>
															<Row gutter={6} wrap={false}>
																<Col className="form__input__textbox"> <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.thumb_file
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.doc_file
																			}`,
																	}}
																	height={100}
																	width={100}
																/>
																</Col>
																<Col className="form__input__textbox printImgCol">
																	<Button
																		key="2"
																		htmlType="button"
																		loading={exportLoading}
																		className="printBtn"
																		onClick={() => {PrintPod(item?.doc_file)}}
																	>
																	<PrintIcon />
																	</Button>
																	<Button
																		key="2"
																		htmlType="button"
																		loading={exportLoading}
																		className="printBtn"
																		onClick={handleSendEmail}
																	>
																	<FontAwesomeIcon icon={faEnvelope} />
																	</Button>
																</Col>
															</Row>
														</>
													}
													{consignmentTrackingValues?.view_type === "detail" && <Row gutter={6} wrap={false} style={{ borderBottom: 0, paddingBottom: 0 }}>
														<label>Captured Images : </label>
													</Row>}
													{item?.image1 ?
														<Row gutter={6} wrap={false}>
															<Col className="form__input__textbox">
																{item?.image1 && <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.image1_thumb
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.image1
																			}`,
																	}}
																	height={100}
																	width={100}
																/>}
															</Col>
															<Col className="form__input__textbox">

																{item?.image2 && <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.image2_thumb
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.image2
																			}`,
																	}}
																	height={100}
																	width={100}
																/>}
															</Col>
															<Col className="form__input__textbox">

																{item?.image3 && <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.image3_thumb
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.image3
																			}`,
																	}}
																	height={100}
																	width={100}
																/>}
															</Col>
															<Col className="form__input__textbox">
																{item?.image4 && <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.image4_thumb
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.image4
																			}`,
																	}}
																	height={100}
																	width={100}
																/>}
															</Col>
															<Col className="form__input__textbox">
																{item?.image5 && <Image
																	className="kycFileUpload"
																	src={
																		Constant.S3BUCKET_API_BASEURL +
																		item?.image5_thumb
																	}
																	preview={{
																		src: `${Constant.S3BUCKET_API_BASEURL +
																			item?.image5
																			}`,
																	}}
																	height={100}
																	width={100}
																/>}
															</Col>
														</Row> : <Row gutter={6} wrap={false}>{Constant.NOT_AVAILABLE_VALUES}</Row>
													}
												</div>
											)
										})}
									</Layout>) : (<Layout className="details_table">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Delivery information is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Delivery information is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>
								)}
							</Panel>

							<Panel header="Wrong Sorting" key="4">
								{consignmentTrackingValues?.wrong_sortings && consignmentTrackingValues?.view_type === "detail" ? <Layout className="details_table">
									<Table
										className="packetentry ViewDRSentry"
										columns={WrongSortingcolumns}
										locale={{ emptyText: vsmCommon.noRecord }}
										dataSource={consignmentTrackingValues?.wrong_sortings}
										pagination={false}
									/>
								</Layout> : (<Layout className="details_table">
									<Row gutter={6} wrap={false}>
										<Col span={24} className="form__input__textbox">
											{/* <i className="mb-0">Wrong Sorting is not available</i> */}
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={
													<span>
														Wrong Sorting is not available
													</span>
												}
											></Empty>
										</Col>
									</Row></Layout>
								)}
							</Panel>
							<Panel header="Lost Shipment" key="5">
								{consignmentTrackingValues?.lost_shipments && consignmentTrackingValues?.view_type === "detail" ?
									<Layout className="details_table">
										<Table
											className="packetentry ViewDRSentry"
											columns={LostShipmentcolumns}
											locale={{ emptyText: vsmCommon.noRecord }}
											dataSource={consignmentTrackingValues?.lost_shipments}
											pagination={false}
										/>
									</Layout> : (<Layout className="details_table">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Lost Shipment is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Lost Shipment is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>
									)}
							</Panel>
							<Panel header="Return To Origin" key="6">
								{consignmentTrackingValues?.Rto && consignmentTrackingValues?.view_type === "detail" ?
									<Layout className="details_table">
										<Table
											className="packetentry ViewDRSentry"
											columns={ReturnToOrigincolumns}
											locale={{ emptyText: vsmCommon.noRecord }}
											dataSource={consignmentTrackingValues?.Rto}
											pagination={false}
										/>
									</Layout> : (<Layout className="details_table">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Return To Origin is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Return To Origin is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>
									)}
							</Panel>
						</Collapse>
						}
					</>
				</Drawer>
				<Modal
					centered
					className="primaryModal"
					title="Delivery Information"
					visible={deliveryViewDetailModel}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onCancel={() => {
						setdeliveryViewDetailModel(false);
					}}
					cancelButtonProps={{ style: { display: "none" } }}
					okButtonProps={{ style: { display: "none" } }}
					width={"50%"}
					zIndex={1002}
					footer={[
						<Button
							key="2"
							type="primary"
							className="cancelBtn mr-35"
							onClick={() => {
								setdeliveryViewDetailModel(false);
							}}
						>
							Cancel
						</Button>
					]}
				>
					<Layout className="details_table">
						<Table
							className="packetentry ViewDRSentry"
							columns={DeliveryInformationcolumns}
							locale={{ emptyText: vsmCommon.noRecord }}
							dataSource={consignmentTrackingValues?.delivery_details}
						/>
					</Layout>
				</Modal>
				<Modal
					title="Send Email" 
					open={isModalOpen}
					className="addModal"
					onCancel={handleCancel}								
					zIndex={1002}
					footer={[
						<Button
							key="2"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={handleCancel}
						>
							Cancel
						</Button>,
						<Button
							key="1"
							// disabled={disabledSave}
							form="SendEmailForm"
							loading={exportLoading}
							htmlType="submit"
							type="primary"
						>
							Save
						</Button>,
					]}
				>
					<Form
						form={form}
						onFinish={onFinish}
						// onChange={handleChange}
						labelCol={{ span: 24 }}
						id="SendEmailForm"
					>
						<InputComponent
							type="text"
							required
							label="Email Id"
							placeholder="Email"
							name="email"
							// onChange={handleChange}
							rules={[
									{ required: true, message: Messages.REQUIRED },
									{
										pattern: Patterns.EMAIL,
										message: Messages.EMAIL_INVALID_CHARACTERS,
									},	
									() => ({
										validator(_, value) {
											if (value && value < 8) {
												let message = replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8);
												return Promise.reject(message);
											} else if (value && value > 70) {
												let message = replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 70);
												return Promise.reject(message);
											}
											return Promise.resolve();
										},
									}),								
								]}
						/>
					</Form>
				</Modal>
			</>
		)
	);
});
export default ConsignmentTrackingComponent;
