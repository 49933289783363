import { getToken, onMessage, isSupported, getMessaging } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { Notify } from "./utils/Notify";

export const getTokenInit = async (setUnreadNotificationCount, unreadNotificationCount) => {
	const firebaseConfig = {
		apiKey: process.env.React_APP_apiKey,
		authDomain: process.env.React_APP_authDomain,
		databaseURL: process.env.React_APP_databaseURL,
		projectId: process.env.React_APP_projectId,
		storageBucket: process.env.React_APP_storageBucket,
		messagingSenderId: process.env.React_APP_messagingSenderId,
		appId: process.env.React_APP_appId,
		measurementId: process.env.React_APP_measurementId
	};

	if (!(await isSupported())) {
		console.warn("Firebase Messaging is not supported in this browser mode.");
		return;
	}

	const app = initializeApp(firebaseConfig);
	const messaging = getMessaging(app);

	const publicKey = process.env.React_APP_VapidKey;

	if ('serviceWorker' in navigator) {
		try {
			const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
			await navigator.serviceWorker.ready;
			console.log('Service Worker registered with scope:', registration.scope);
			const fcmToken = await getToken(messaging, { vapidKey: publicKey, serviceWorkerRegistration: registration });

			if (fcmToken) {
				localStorage.setItem("fcmToken", fcmToken);
			} else {
				console.warn("No registration token available.");
			}

			onMessage(messaging, (payload) => {
				setUnreadNotificationCount(unreadNotificationCount + 1);
				Notify.custome(payload);
			});
		} catch (err) {
			console.warn('Service worker registration failed:', err);
		}
	} else {
		console.warn("Service workers are not supported in this browser.");
	}
};