import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { Notify } from "../utils/Notify";
import { ApiConstant } from "../config/ApiConstant";
import { stringEncryption, stringDecryption } from "../utils/GlobalFunction";
import { db } from "./../db";
import Dexie from "dexie";
import { Constant } from "../config/Constant";

export default class AuthStore {
	user = null;
	unreadNotificationCount = 0;
	token = null;
	sessionId = null;
	menu = null;
	reportsMenu = null;
	loading = true;
	open_login = false;
	open_LICENSE_EXPIRED = false;
	permissions = null;
	company = null;
	privileges = null;
	remember_me = null;
	fcmToken = null;
	UUID = null;
	version = process.env.REACT_APP_VERSION;
	logOutValues = null;
	avtarValues = null;
	editAvtarValues = null;
	communicationData = null;
	widgetsData = null;
	ediEmailValues = null;
	alertModal = false;
	notificationMenu = null;
	activeNotificationMenu = null

	constructor() {
		this.initiatAppOptions();
	}

	setLogOutValues = (data) => {
		this.logOutValues = data;
	}

	setAvtarValues = (data) => {
		this.avtarValues = data
	}

	setEditAvtarValues = (data) => {
		this.editAvtarValues = data
	}

	setEmailValues = (data) => {
		this.ediEmailValues = data
	}

	// set interceptors to axios 200, 404, 500, 403, 422
	setAxiosInterceptors = () => {
		Axios.interceptors.response.use(
			(data) => {
				return data;
			},
			(data) => {
				if (data.response) {
					if (data.response.status === 401 || data.response.status === 403) {
						if (data.response && data.response.data.STATUS.NOTIFICATION) {
							data.response.data.STATUS.NOTIFICATION.map((err) =>
								Notify.error({
									message: err,
								})
							);
							if (data.response.status === 403) {
								if (data.response.data.hasOwnProperty('STATUS_KEY') && data.response.data.STATUS_KEY === "LICENSE_EXPIRED") {
									this.open_LICENSE_EXPIRED = true;
									this.loading = false;
								} else {
									localStorage.clear();
									this.open_login = true;
									this.initCall();
								}
							}
						} else {
							localStorage.clear();
							this.open_login = true;
							this.initCall();
						}
					} else if (data.response.status === 429) {
						Notify.error({
							message: data?.response?.data?.message,
						})
					}
					/*else if (data.response.status === 403) {
						if (data.response && data.response.data.STATUS.NOTIFICATION) {
							if (data.response.data.STATUS_KEY) {
								if (data.response.data.STATUS_KEY === "LICENSE_EXPIRED") {
									data.response.data.STATUS.NOTIFICATION.map((err) =>
										Notify.error({
											message: err,
										})
									);
									this.open_LICENSE_EXPIRED = true;
									this.loading = false;
								} else {
									data.response.data.STATUS.NOTIFICATION.map((err) =>
										Notify.error({
											message: err,
										})
									);
								}
							} else {
								data.response.data.STATUS.NOTIFICATION.map((err) =>
									Notify.error({
										message: err,
									})
								);
							}
						}
					}*/ else if (
						data.response.status !== 200 ||
						data.response.status !== 201
					) {
						if (data.response.data && data.response.data.STATUS.NOTIFICATION) {
							data.response.data.STATUS.NOTIFICATION.map((err) =>
								Notify.error({
									message: err,
								})
							);
						} else if (data.response.status === 500) {
							Notify.error({
								message: "Something Went Wrong"
							});
						}
					} else {
						Notify.error({
							message: "Network Problem",
						});
					}
				}
				return Promise.reject(data);
			}
		);
	};

	// set axios default setting for api call
	initiatAppOptions = () => {
		this.loading = true;
		Axios.defaults.baseURL = ApiConstant.API_BASEURL;
		let token = localStorage.getItem("token");
		let sessionId = localStorage.getItem("sessionId") ?? null;
		let company = localStorage.getItem("company");
		let remember_me = localStorage.getItem("remember_me");
		this.remember_me = remember_me
			? JSON.parse(stringDecryption(remember_me))
			: null;

		let UUID = localStorage.getItem("UUID");
		this.UUID = UUID ?? null;

		let app_version = localStorage.getItem("app_version");
		this.version = app_version ?? process.env.REACT_APP_VERSION;

		if (company && company !== "undefined") {
			company = JSON.parse(company);
			this.company = company;
			document.documentElement.style.setProperty(
				"--main-color",
				company.branding.theme_color
			);
			if (token && token !== "undefined") {
				Axios.defaults.headers = {
					Accept: "application/json",
					Authorization: "Bearer " + token,
					"api-key": ApiConstant.API_KEY,
					"app-id": ApiConstant.APP_ID,
					"device-key": this.UUID,
					platform: ApiConstant.PLATEFORM,
					"session-id": sessionId
				};
				this.setUserOptions(token);
				this.getCommunicationData();
				this.getwidgetsData();
			} else {
				Axios.defaults.headers = {
					Accept: "application/json",
					"api-key": ApiConstant.API_KEY,
					"app-id": ApiConstant.APP_ID,
					"device-key": this.UUID,
					platform: ApiConstant.PLATEFORM,
				};
				this.setUser(null, null);
				this.loading = false;
			}
		} else {
			this.initCall();
		}
	};

	initCall = () => {
		this.setAxiosInterceptors();
		Axios.defaults.headers = {
			Accept: "application/json",
			platform: ApiConstant.PLATEFORM,
		};
		const data = {};
		// const data = { custom_response: "LICENSE_EXPIRED" }
		return Axios.post("general/auth/init", data)
			.then(({ data }) => {
				let UUID = localStorage.getItem("UUID");
				this.UUID = UUID ?? null;
				if (data) {
					localStorage.setItem("company", JSON.stringify(data.company));
					Axios.defaults.headers = {
						Accept: "application/json",
						"api-key": ApiConstant.API_KEY,
						"app-id": ApiConstant.APP_ID,
						"device-key": this.UUID,
						platform: ApiConstant.PLATEFORM,
					};
					this.company = data.company;
					document.documentElement.style.setProperty(
						"--main-color",
						data.company.branding.theme_color
					);
					// let token = localStorage.getItem('token')
					// if (token === null) {
					// 	this.open_login = true
					// }
					this.loading = false;
					this.open_LICENSE_EXPIRED = false;
				}
				return true;
			})
			.catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	};
	// make a api call to get current user & accessible menu
	setUserOptions = (token) => {
		Axios.post("general/auth/me")
			.then(({ data }) => {
				if (data.user.privileges) {
					this.privileges = data.user.privileges;
				} else if (data.user.role.length) {
					this.privileges = data.user.role[0].privileges;
				}
				this.setUser(data.user, token);
				this.setUnreadNotificationCount(data.user?.unread_notification ?? 0);
				this.setNotificationMenu(data.user.notification_menu)
				var menu = data.user.menu;
				//let index = menu.findIndex((x) => x.id === 10);
				//if (index !== -1) {
				if (data.user && data.user.reports_menu.length > 0) {
					this.reportsMenu = data.user.reports_menu
					//menu.splice(index, 1);
				}
				else {
					this.reportsMenu = null
				}
				// this.loading = false;
				if (this.version && data.user.app_release) {
					if (data.user.app_release.release !== this.version) {
						if (window.ipcRenderer) {
							window.ipcRenderer.send('versionUpdate', { app_release: data.user.app_release });
						} else {
							this.loading = false;
						}
					} else {
						this.loading = false;
					}
				} else {
					this.loading = false;
				}
				this.menu = menu;
				if (localStorage.getItem('syncBooking') === null) {
					this.setBooking();
				}
				if (data.user.level_id === Constant.LOCATION_USER && (data.user.lock.lck_tracking === 1 || data.user.lock.lck_part_tracking === 1 || data.user.lock.lck_int_tracking === 1 || data.user.lock.lck_part_int_tracking === 1)) {
					this.alertModal = true
				}
				else {
					this.alertModal = false
				}
			})
			.catch((e) => {
				localStorage.clear();
				this.initiatAppOptions();
			});
	};
	getCommunicationData = () => {
		Axios.get("/general/auth/communication")
			.then(({ data }) => {
				this.communicationData = data.communication
			})
			.catch((e) => {
			});
	};
	getwidgetsData = () => {
		Axios.get("/general/auth/widgets")
			.then(({ data }) => {
				this.widgetsData = data.widgets
			})
			.catch((e) => {
			});
	};
	// set authenticated user to mobx store
	setUser = (user, token) => {
		this.user = user;
		this.token = token;
	};

	setUnreadNotificationCount = (count) => {
		this.unreadNotificationCount = count
	}
	setNotificationMenu = (count) => {
		this.notificationMenu = count
	}
	setActiveNotificationMenu = (count) => {
		this.activeNotificationMenu = count
	}

	// make a api call to get current user & accessible menu
	setBooking = () => {
		if ((this.checkPrivileges("#1060002#") || this.checkPrivileges("#1060003#") || this.checkPrivileges("#1060022#") || this.checkPrivileges("#1060023#"))) {
			Axios.post("general/auth/bk-sync")
				.then(({ data }) => {
					if (data.bk_sync.senders && data.bk_sync.senders.length > 0) {
						db.senders.bulkPut(data.bk_sync.senders).then(function (lastKey) {
							console.log("Last senderList id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some senderList did not succeed.");
						});
					}
					if (data.bk_sync.receivers_cash && data.bk_sync.receivers_cash.length > 0) {
						db.receivers_cash.bulkPut(data.bk_sync.receivers_cash).then(function (lastKey) {
							console.log("Last receiversCash id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some receiversCash did not succeed.");
						});
					}
					if (data.bk_sync.receivers_ac && data.bk_sync.receivers_ac.length > 0) {
						db.receivers_ac.bulkPut(data.bk_sync.receivers_ac).then(function (lastKey) {
							console.log("Last receiversAcc id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some receiversAcc did not succeed.");
						});
					}
					if (data.bk_sync.bk_cash && data.bk_sync.bk_cash.length > 0) {
						db.bk_cash.bulkPut(data.bk_sync.bk_cash).then(function (lastKey) {
							console.log("Last cashList's id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some cashList did not succeed.");
						});
					}
					if (data.bk_sync.bk_account && data.bk_sync.bk_account.length > 0) {
						db.bk_account.bulkPut(data.bk_sync.bk_account).then(function (lastKey) {
							console.log("Last accountList id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some accountList did not succeed.");
						});
					}
					if (data.bk_sync.states && data.bk_sync.states.length > 0) {
						db.states.bulkPut(data.bk_sync.states).then(function (lastKey) {
							console.log("Last StateList id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some StateList did not succeed.");
						});
					}
					if (data.bk_sync.insurance_details) {
						db.insurance_details.put(data.bk_sync.insurance_details).then(function (lastKey) {
							console.log("Last InsuranceList id was: " + lastKey);
						}).catch(Dexie.BulkError, function (e) {
							console.error("Some InsuranceList did not succeed.");
						});
					}
					localStorage.setItem('syncBooking', "synced");
				})
				.catch((e) => {
					localStorage.clear();
					this.initiatAppOptions();
				});
		}
	};

	clearSetBooking = () => {
		localStorage.removeItem('syncBooking');
		db.senders.clear();
		db.receivers_cash.clear();
		db.receivers_ac.clear();
		db.bk_cash.clear();
		db.bk_account.clear();
	}

	// call api for login
	doLogin = (formdata) => {
		formdata.device_token = localStorage.getItem("fcmToken") || this.fcmToken;
		formdata.version = this.version;

		// this.initCall().then((resp) => {
		return Axios.post("general/auth/login", formdata)
			.then(({ data }) => {
				if (formdata.remember) {
					let string = JSON.stringify({
						email: formdata.username,
						password: formdata.password,
					});
					localStorage.setItem("remember_me", stringEncryption(string));
				}
				if (data?.otp_on_login === 0) {
					localStorage.setItem("token", data.token);
					localStorage.setItem("sessionId", data.session_id);
					this.initiatAppOptions();
					this.open_login = false;
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
		// })
	};

	// call api for forgot password link
	sendForgotPasswordLink = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/forgot-password", data)
			.then(({ data }) => {
				this.setEmailValues(data);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for verify otp
	verifyOTP = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/verify-otp", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for change name
	changeName = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		// data.device_token = localStorage.getItem("previous_token")
		return Axios.post("general/auth/change-name", data)
			.then(({ data }) => {
				localStorage.setItem("token", data.token);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for verify otp
	verifyEmailOTP = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/send-email-otp", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for change user name
	changeUserName = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/change-username", data)
			.then(({ data }) => {
				localStorage.setItem("token", data.token);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for change mobile
	ChangeMobile = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/change_mobile", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for verify otp MOBILE
	verifyMobileOTP = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/send-mobile-otp", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for change email
	ChangeEmail = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/change_email", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for verify otp
	verifySMSOTP = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};

		data.device_token = localStorage.getItem("fcmToken") || this.fcmToken;
		data.version = this.version;

		return Axios.post("general/auth/verify-sms-otp", data)
			.then(({ data }) => {
				localStorage.setItem("token", data.token);
				this.initiatAppOptions();
				this.open_login = false;
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};


	// call api for reSendSMSOtp
	reSendSMSOtp = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/resend-sms-otp", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for verify email
	verifyEmail = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.get(`general/auth/email-verification/${data}`)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for Client email verify 
	verifyClientEmail = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.get(`general/auth/email-client-verification/${data}`)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api for forgot password link
	doResetPassword = (data) => {
		Axios.defaults.headers = {
			Accept: "application/json",
			"api-key": ApiConstant.API_KEY,
			"app-id": ApiConstant.APP_ID,
			"device-key": this.UUID,
			platform: ApiConstant.PLATEFORM,
		};
		return Axios.post("general/auth/reset-password", data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// logout function
	doLogout = () => {
		return Axios.post("general/auth/logout")
			.then(({ data }) => {
				if (localStorage.getItem("previous_token")) {
					localStorage.setItem("token", localStorage.getItem("previous_token"));
					localStorage.removeItem("previous_token");
				} else {
					this.resetAuthUser();
				}
				this.initiatAppOptions();
				this.alertModal = false
				this.communicationData = null
				return data;
			})
			.catch(({ response: { data } }) => {
				this.loading = false;
				return Promise.reject();
			});
	};

	resetAuthUser = () => {
		this.setUser(null, null);
		this.setUnreadNotificationCount(0);
		this.sessionId = null;
		this.menu = null;
		this.privileges = null;
		this.loading = false;
		this.fcmToken = localStorage.getItem("fcmToken");
		let UUID = localStorage.getItem("UUID");
		let PLATFORMOS = localStorage.getItem("PLATFORMOS");
		let remember_me = localStorage.getItem("remember_me");
		localStorage.clear();
		localStorage.setItem("fcmToken", this.fcmToken);
		localStorage.setItem("UUID", UUID);
		localStorage.setItem("PLATFORMOS", PLATFORMOS);
		if (remember_me) {
			localStorage.setItem("remember_me", remember_me);
		}
		/**
		 * Clear all indexDB data 
		 */
		this.clearSetBooking();
	}

	// Check User Privileges
	checkPrivileges = (permission_type) => {
		if (this.privileges && this.privileges.includes(permission_type)) {
			return true;
		} else {
			return false;
		}
	};

	changePassword = (formdata) => {
		return Axios.post(`general/auth/change-password`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call Proxy Login
	ProxyLogin = (data) => {
		data.device_token = localStorage.getItem("fcmToken") || this.fcmToken;
		return Axios.post(`admin/users/proxy-login/` + data.id)
			.then(({ data }) => {
				localStorage.setItem("token", data.token);
				if (data?.session_id) {
					localStorage.setItem("sessionId", data.session_id);

				}
				this.clearSetBooking();
				this.initiatAppOptions();
				this.open_login = false;
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
		// })
	};
	setLocalStorageToStore = () => {
		let company = localStorage.getItem('company')
		if (company && company !== 'undefined') {
			company = JSON.parse(company)
			this.company = company
		}
	}

	ChangeLocationData = (ID) => {
		/**
		 * Clear all indexDB data 
		 */
		this.clearSetBooking();
		return Axios.post(`admin/users/change-location`, { location_id: ID }).then(({ data }) => {
			this.user.default_location = ID;
			return data;
		});
	};

	// set authenticated user to mobx store
	updateUser = (field, value) => {
		if (this.user[field]) {
			this.user[field] = value;
		}
	};

	AllocateOldStickers = (conditional = {}) => {
		return Axios.post('/organization/location/allocate-old-stickers', conditional)
			.then(({ data }) => {
				this.initiatAppOptions();
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	/**
	 * Check center has any lock or not.
	 */
	checkCenterHasLocked = (data) => {
		return Axios.post('/locks/center/check', data)
			.then(({ data }) => {
				if (data.lock) {
					this.user.lock.lck_bk_ac = data.lock.lck_bk_ac
					this.user.lock.lck_bk_cash = data.lock.lck_bk_cash
					this.user.lock.lck_int_tracking = data.lock.lck_int_tracking
					this.user.lock.lck_part_int_tracking = data.lock.lck_part_int_tracking
					this.user.lock.lck_part_tracking = data.lock.lck_part_tracking
					this.user.lock.lck_tracking = data.lock.lck_tracking
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}
}

decorate(AuthStore, {
	user: observable,
	token: observable,
	sessionId: observable,
	menu: observable,
	loading: observable,
	open_login: observable,
	open_LICENSE_EXPIRED: observable,
	permissions: observable,
	company: observable,
	fcmToken: observable,
	remember_me: observable,
	privileges: observable,
	reportsMenu: observable,
	setUser: action,
	setUnreadNotificationCount: action,
	unreadNotificationCount: observable,
	doLogin: action,
	doLogout: action,
	checkPrivileges: action,
	changePassword: action,
	logOutValues: observable,
	avtarValues: observable,
	editAvtarValues: observable,
	setLogOutValues: action,
	setAvtarValues: action,
	setEditAvtarValues: action,
	ediEmailValues: observable,
	setEmailValues: action,
	alertModal: observable,
	setNotificationMenu: action,
	notificationMenu: observable,
	setActiveNotificationMenu: action,
	activeNotificationMenu: observable,
});
